export const SERVICE_CENTER_LOCATION_ID = "9e991b1a-dbfa-4173-b050-de9a5da9b3fb";

export enum DEPLOYMENT_TYPES {
  COMMERCIAL = "0353cc55-fec5-4d0d-b8ec-0e1b53d3f625",
  INTERNAL = "400c0456-3d46-4b2a-96b8-2e98da9843d2",
  TRANSIT = "f62fc9d4-4817-477e-9b9f-83a0fde49db5",
  CREATED = "c2562a96-587b-46b7-bf5d-f81cad4270c5",
  DEMO = "8f9b82c0-cefc-4aaa-a9ca-6f39dedd6b82",
  RETIRED = "1aaf32bb-d875-4c3a-8c29-e874adc85ba7",
  NEW_READY_FOR_DEPLOYMENT = "ff74a26f-7a71-4466-8cc3-96f09a1ba46b",
  REFURBISH_FOR_DEPLOYMENT = "fe9057d9-8577-4307-a7cb-244f815a29fe",
  COMMERCIALSOLD = "e36238f6-bac7-4de0-8169-bd062288b834",
  COMMERCIALLEASED = "bccb24bc-b1e7-4b02-97ef-f81b66c0e7cc",
  IN_REPAIR = "5368a79b-0e30-42c4-aaab-4d9ca28769f7",
  REFURBISHED_READY_FOR_DEPLOYMENT = "f4c41d03-2884-4b69-857e-716f79899742",
}

export enum MACHINE_STATUS {
  CREATED = "6db0f532-3706-4f9d-8a13-46a526703210",
  RETIRED = "a95da2ae-60f2-4604-82b3-8d829f070f95",
  IN_REPAIR = "cfef8914-730a-4e19-8fe2-1711c23914ac",
  NEW_READY_FOR_DEPLOYMENT = "36588335-d898-4268-922c-d9ab3e93c54e",
  REFURBISH_READY_FOR_DEPLOYMENT = "e3e2b5bf-b308-4e45-827e-8dee135bdf5f",
  TRANSIT = "8da84ae7-8402-4b79-b295-6d453a82743a",
  DEPLOYED = "4f7294eb-aac6-4fb7-8f32-6fa1654c6f4f",
}

export enum LOCATION_TYPES {
  COMMERCIAL = "061adf37-2c28-4ed7-9b54-43336f055fef",
  INTERNAL = "f6ce00d1-624c-4209-b90c-9d2b0b712142",
  DEMO = "d52c42e3-3ed0-4764-92bf-d9b41e16499c",
  DISTRIBUTOR = "091df432-f9d8-4103-bcb5-8cd625b34ccd",
  OTHER_THIRD_PARTY = "979f3f40-5789-442c-bf8f-95253a9f00b8",
  PENDING = "c6bcd61c-8601-4661-ab72-13e5a6f5bf0a",
}

export enum BUSINESS_MODELS {
  SOLD = "f214d672-327b-4ec8-a7ae-7a4f9ff8c896",
  LEASED = "7023776c-76cb-43fd-9e47-c75728c69ec7",
  PLACED = "987f3132-444a-42c5-8e01-f6e8190d5d04",
  FREE = "7832a568-bc1a-465e-89d3-79c675519efc",
}

export enum MARKET_SEGMENTS {
  COLLEGE_OR_UNIVERSITY = "c7f28e56-6931-4a0a-bbe2-ded3ce4463c5",
  CONVENIENCE_STORE = "fa01e03d-d8fa-4bfb-8f5e-8fd7c99aee47",
  GROCERY_STORE = "c0fccc5e-823d-4ac6-859f-28d283864c8a",
  HOSPITAL = "8282c3a3-5c12-4b64-bc5c-d6983bfb59fe",
  OFFICE = "e5edbd32-ec0a-4975-922a-406b474f74d4",
  OTHER = "cb54c51a-1b01-44d2-b5ae-93424868355f",
}

export enum MACHINE_SERVICES {
  REPLACE_MACHINE = "a52bb333-aa9c-4a2a-b72b-8ef020bcc93e",
  REPLACE_PCB = "dbc96680-1526-4707-886f-d51f0ba28c0c",
  UPGRADE_PCB = "35768bc1-ff02-428e-9669-3969818b32c0",
  REPLACE_BLENDER_ASSEMBLY = "8a8b4c94-c806-4bba-a06c-469dd2028513",
  REPLACE_TUBE_DOOR = "0c2a9460-338e-4c22-b0c2-2197a6c28cfc",
}

export enum FOLDER_NAME {
  TUBE_SHIELD_IMAGES = "tube-shield-images",
  WORK_ORDER_IMAGES = "work-order-images",
  BLENDER_IMAGES = "blender-images",
  BOARD_IMAGES = "board-images",
  CUP_HOLDER_IMAGES = "cup-holder-images",
}

export enum WORK_ORDER_STATUS {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
}