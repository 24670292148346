import { FC, useEffect, useState } from "react";
import Moment from "moment";
import { Pagination, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { getUsers } from "../../../../models/_user";
import { getLocationRevisions } from "../../../../models/_location";

export const getFieldName = (field: string) => {
  const fieldNames: { [key: string]: string } = {
    updated_at: "Updated At",
    created_at: "Created At",
    name: "Location Name",
    contact_name: "Contact Name",
    target_launch_date:"Target Launch Date",
    no_of_blenders:"No of Blenders",
    deleted_by_id: "Deleted By",
    updated_by_id: "Updated By",
    created_by_id: "Created By",
    customer_id:"Customer Name",
    sampling_recommended_time :"Sampling Recommended Time",
    sampling_recommended_days:"Sampling Recommended Days",
    city:"City",
    state:"State",
    latitude:"Latitude",
    longitude:"Longitude",
    address:"Address",
    customer_second_contact:"Customer Second Contact",
    second_contact_name:"Second Contact Name",
    type_of_second_contact_phone_number:"Type of Second Contact Phone Number",
    second_contact_phone_number:"Second Contact Phone Number",
    second_contact_email:"Second Contact Email",
    second_contact_name_role:"Second Contact Name Role",
    customer_it_contact:"Customer IT Contact",
    type_of_it_contact_phone_number:"Type of IT Contact Phone Number",
    it_contact_name:"IT Contact Name",
    it_contact_phone_number:"IT Contact Phone Number",
    it_contact_email:"IT Contact Email",
    it_contact_name_role:"IT Contact Name Role",
    customer_service_contact:"Customer Service Contact",
    type_of_service_contact_phone_number:"Type of Service Contact Phone Number",
    service_contact_name:"Service Contact Name",
    service_contact_phone_number:"service_contact_phone_number",
    service_contact_email:"Service Contact Email",
    service_contact_name_role:"Service Contact Name Role",
    wifi_credentials:"Wifi Credentials",
    store_manager_contact:"Store Manager Contact",
    type_of_store_manager_contact_phone_number:"Type of Store Manager Contact Phone Number",
    store_manager_contact_name:"Store Manager Contact Name",
    store_manager_contact_phone_number:"Store Manager Contact Phone Number",
    store_manager_contact_email:"Store Manager Contact Email",
    is_warehouse:"Is Warehouse",
    zip_code:"Zip Code",
    location_type_id:"Location Type",
    deployment_manager_id:"Deployment Manager Name",
    region_id:"Region Name",
    gas_supplier_id:"Gas Supplier Name",
    market_segment_id:"Market Segment Name",
    business_model_id:"Business Model Name",
    contact_phone:"Contact Phone",
    contact_email:"Contact Email",
    distributor_id:"Distributor Name",
    no_of_freezers:"Number of Freezers",
    filter_provider_id:"Filter Provider Name",

  };
  return fieldNames[field] || field;
};
//  	
type Props = {
  locationHistory: any;
  page: any;
  perPage: any;
  setPage: (page: any) => void;
};

const LocationRevision: FC<Props> = ({
  locationHistory,
  page,
  perPage,
  setPage,
}) => {
  const [selectedRevisions, setSelectedRevisions] = useState<any[]>([]);
  const [locationHistoryRevisions, setLocationHistoryRevisions] = useState<any>([])
  const [locationHistoryData, setLocationHistoryData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [userList, setUserList] = useState([]);

  const handleCheckboxChange = (revision: any, isChecked: boolean) => {
    setSelectedRevisions((prev) => {
      if (isChecked) {
        if (prev.length < 2) {
          return [...prev, revision];
        } else {
          toast.error("You can only select 2 revisions");
          return prev;
        }
      } else {
        return prev.filter((r) => r.id !== revision.id);
      }
    });
  };

  const handleGetLocationRevisions = async (locationHistoryIds: string[]) => {
    const response = await getLocationRevisions({ ids: locationHistoryIds });
    setLocationHistoryRevisions(response);
  };

  useEffect(() => {
    if (selectedRevisions.length === 2) {
      const ids = selectedRevisions.map((revision: any) => revision.id);
      handleGetLocationRevisions(ids);
    } else {
      setLocationHistoryRevisions([]);
    }
  }, [selectedRevisions, setSelectedRevisions]);

  useEffect(() => {
    setLocationHistoryData(locationHistory?.data);
    setTotalPages(locationHistory?.totalPages ?? 0);
  }, [locationHistoryData, locationHistory]);

  const handleGetUserList = async () => {
    const response = await getUsers();
    if(response.length > 0){
      setUserList(response)
    }
  };

  useEffect(()=>{
    handleGetUserList();
  },[])

  return (
    <div>
      <div className="row">
        <div className="col-7">
          <div className="card mt-9 mx-xl-4">
            <div className="card-body w-100 p-5">
              <div className="table-responsive p-0 pb-2 fs-6 border-0 p-5">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <thead>
                    <tr className="fs-5 fw-bold">
                      <th className="fs-5 text-muted">Select Version</th>
                      <th className="fs-5 text-muted">Versions</th>
                      <th className="fs-5 text-muted">Created At</th>
                      <th className="fs-5 text-muted">Updated By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {locationHistoryData?.length > 0 ? (
                      locationHistoryData.map((revision: any, index: number) => (
                        <tr key={index}>
                          <td>
                            <label className="form-check form-check-sm form-check-custom form-check-solid align-items-center">
                              <input
                                className="form-check-input ms-2"
                                type="checkbox"
                                checked={selectedRevisions.some(
                                  (r) => r.id === revision.id
                                )}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    revision,
                                    e.target.checked
                                  )
                                }
                              />
                            </label>
                          </td>
                          <td>{(page - 1) * perPage + index + 1}</td>
                          <td>
                            {Moment(revision.operationExecutedAt)
                              .local()
                              .format("YYYY-MM-DD HH:mm")}
                          </td>
                          <td>
                           {userList?.filter((user:any) => user.id === revision.dataAfterExecutedOperation.updated_by_id)?.map((user:any) => user.firstName)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          align="center"
                          className="text-center align-align-items-center justify-content-center"
                          colSpan={3}
                        >
                          No records found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {locationHistoryData?.length > 0 && (
              <div className="card-footer d-flex justify-content-end">
                <Pagination>
                  <Pagination.First
                    onClick={() => setPage(1)}
                    disabled={page === 1}
                  >
                    First
                  </Pagination.First>
                  <Pagination.Prev
                    onClick={() => setPage(page - 1)}
                    disabled={page === 1}
                  >
                    Previous
                  </Pagination.Prev>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === page}
                      onClick={() => setPage(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() => setPage(page + 1)}
                    disabled={page === totalPages}
                  >
                    Next
                  </Pagination.Next>
                  <Pagination.Last
                    onClick={() => setPage(totalPages)}
                    disabled={page === totalPages}
                  >
                    Last
                  </Pagination.Last>
                </Pagination>
              </div>
            )}
          </div>
        </div>
        {locationHistoryRevisions?.length > 0 && (
          <div className="col-7">
            <div className="card mt-9 mx-xl-4">
              <div className="mt-3 col-md-12">
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th style={{ width: "20%" }}>Field</th>
                      <th style={{ width: "40%" }}>Old Location Value</th>
                      <th style={{ width: "40%" }}>New Location Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {locationHistoryRevisions.map(
                      (change: any, i: any) => (
                        <tr key={i}>
                          <td>{getFieldName(change.path)}</td>
                          <td className="text-danger">
                            <i className="me-2 text-danger bi bi-dash-circle"></i>
                            {change.oldVal === true
                              ? "Yes"
                              : change.oldVal === false
                              ? "No"
                              : change.oldVal ? (Moment(change.oldVal.toString(),"", true).isValid()
                              ? Moment.utc(change.oldVal)
                                  .local()
                                  .format("YYYY-MM-DD HH:mm")
                              : change.oldVal) : "N/A"}
                          </td>
                          <td className="text-success">
                            <i className="me-2 text-success bi bi-plus-circle"></i>
                            {change.newVal === true
                              ? "Yes"
                              : change.newVal === false
                              ? "No"
                              : change.newVal ? (Moment(change.newVal.toString(),"", true).isValid()
                              ? Moment.utc(change.newVal)
                                  .local()
                                  .format("YYYY-MM-DD HH:mm")
                              : change.newVal) : "N/A"}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationRevision;
