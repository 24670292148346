import { FC, useState, Suspense, useEffect } from "react";
import Modal from "react-responsive-modal";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { ErrorMessage } from "formik";
import Select from "react-select";
import clsx from "clsx";
import { getCustomers } from "../../models/_customer";
import toast, { Toaster } from "react-hot-toast";
import { getLocations } from "../../models/_location";
import {
  getCustomerRoleList,
  updateCustomerAccount,
} from "../../models/_customerAccounts";
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from "react-relay";
import { EditCustomerAccount_getCustomerAccountByIdQuery } from "./__generated__/EditCustomerAccount_getCustomerAccountByIdQuery.graphql";

export const getCustomerAccountById = graphql`
  query EditCustomerAccount_getCustomerAccountByIdQuery($id: String!) {
    getCustomerAccountById(id: $id) {
      id
      name
      email
      password
      locationId
      customerId
      roleId
      portalUserId
      createdById
      updatedById
      deletedById
      updatedAt
      createdAt
      deletedAt
      createdBy {
        id
        firstName
      }
      updatedBy {
        id
        firstName
      }
      location {
        id
        name
      }
      customer {
        id
        name
      }
      customerRole {
        id
        slug
        name
      }
    }
  }
`;

type Props = {
  currentCustomerAccountId: string;
  handleCustomerAccountClose: () => void;
  customerAccountEditModal: boolean;
  refreshTable?: any;
};
const editCustomerAccountSchema = yup.object().shape({
  name: yup.string().required("name is required").trim(),
  email: yup
    .string()
    .required("Email is required")
    .email("Enter valid email")
    .trim(),
  roleId: yup.string().required("Customer role is required"),
  customerId: yup.string().required("Customer is required"),
});

export const EditCustomerAccount: FC<Props> = ({
  currentCustomerAccountId,
  customerAccountEditModal,
  handleCustomerAccountClose,
  refreshTable,
}) => {
  const navigate = useNavigate();
  console.log("currentCustomerAccountId", currentCustomerAccountId);
  if (!currentCustomerAccountId) {
    navigate("/customer/accounts/list");
  }

  const [loading, setLoading] = useState(false);
  const [rolesDB, setRolesDB] = useState([]);
  const [locationDB, setLocationDB] = useState([]);
  const [customerDB, setCustomerDB] = useState([]);

  const customerRecord =
    useLazyLoadQuery<EditCustomerAccount_getCustomerAccountByIdQuery>(
      getCustomerAccountById,
      {
        id: currentCustomerAccountId ?? "",
      },
      {
        fetchPolicy: "network-only",
      },
    );
  const customerAccount: any = customerRecord?.getCustomerAccountById ?? [];

  if (customerAccount == null) {
    navigate("/customer/accounts/list");
  }

  const fetchdata = async () => {
    try {
      const locationIdRecord = await getLocations();
      const locationIdResults: any = locationIdRecord?.data
        ? locationIdRecord?.data
        : [];
      locationIdResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const customerIdRecord = await getCustomers();
      const customerIdResults: any = customerIdRecord?.data
        ? customerIdRecord?.data
        : [];
      customerIdResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const roleRecord = await getCustomerRoleList();
      const roleResults: any = roleRecord ? roleRecord : [];
      roleResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      setRolesDB(roleResults);
      setCustomerDB(customerIdResults);
      setLocationDB(locationIdResults);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchdata();
  }, []);

  return (
    <Suspense>
      <Modal
        open={customerAccountEditModal}
        onClose={handleCustomerAccountClose}
        role="modal"
        center
        classNames={{
          root: "content-wrapper",
        }}
      >
        <Formik
          enableReinitialize
          initialValues={{
            id: customerAccount?.id ?? "",
            name: customerAccount?.name ?? "",
            email: customerAccount?.email ?? "",
            roleId: customerAccount?.customerRole?.id ?? "",
            roleName: customerAccount?.customerRole?.name ?? "",
            customerId: customerAccount?.customer?.id ?? "",
            customerName: customerAccount?.customer?.name ?? "",
            locationId: customerAccount?.location?.id ?? "",
            locationName: customerAccount?.location?.name ?? "",
          }}
          validationSchema={editCustomerAccountSchema}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            setLoading(true);
            const { name, email, roleId, customerId, locationId, id } = values;
            let updateData: any = {
              name,
              customerId,
            };

            if (locationId) {
              updateData["locationId"] = locationId;
            }

            if (email !== customerAccount?.email) {
              updateData["email"] = email;
            }

            if (roleId !== customerAccount?.customerRole?.id) {
              updateData["roleId"] = roleId;
            }


            const result = await updateCustomerAccount(id, updateData);

            if (result?.data?.data?.updateCustomerAccount) {
              setLoading(false);
              toast.success("Customer account updated successfully");
              handleCustomerAccountClose();
              resetForm();
              if (typeof refreshTable !== "undefined") {
                refreshTable();
              }
            } else {
              setLoading(false);
              const messages = result?.data?.errors.map((e: any) => e.message);
              toast.error(messages.join(","));
            }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <form
              id="kt_modal_add_lesson_form"
              className="form"
              onSubmit={handleSubmit}
              autoComplete="off"
              noValidate
            >
              <div className="modal-header p-2 pb-4 mb-4">
                <h4 className="modal-title fs-1">Edit Customer Account</h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 required fw-bold">
                      Name
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid": Boolean(errors.name) && touched.name,
                      })}
                      name="name"
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="name" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 required fw-bold">
                      Email
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid": Boolean(errors.email) && touched.email,
                      })}
                      name="email"
                      id="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="email" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="customer form-label fs-4 required fw-bold">
                      Customers
                    </label>{" "}
                    <Select
                      defaultValue={{
                        label: values.customerName,
                        value: values.customerId,
                      }}
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.customerId) && touched.customerId,
                      })}
                      classNamePrefix="my-react-select"
                      name="customerId"
                      placeholder=""
                      options={Array.from(customerDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("customerId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="customerId" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="customer form-label fs-4 fw-bold">
                      Locations
                    </label>{" "}
                    <Select
                      defaultValue={{
                        label: values.locationName,
                        value: values.locationId,
                      }}
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.locationId) && touched.locationId,
                      })}
                      classNamePrefix="my-react-select"
                      name="locationId"
                      placeholder=""
                      options={Array.from(locationDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("locationId", e.value);
                      }}
                    />
                  </div>

                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 required fw-bold">
                      Customer Role
                    </label>
                    <Select
                      defaultValue={{
                        label: values.roleName,
                        value: values.roleId,
                      }}
                      className={clsx("react-select", {
                        "is-invalid": Boolean(errors.roleId) && touched.roleId,
                      })}
                      classNamePrefix="my-react-select"
                      name="roleId"
                      placeholder=""
                      options={Array.from(rolesDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("roleId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="roleId" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer mt-6 p-0 justify-content-start">
                <button
                  type="submit"
                  className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
                >
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  )}
                  {!loading && <span className="indicator-label">UPDATE</span>}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
    </Suspense>
  );
};
