import React, { Suspense } from "react";
import { getTickets } from "../../models/_tickets";
import Moment from "moment";
import { AUTH_LOCAL_STORAGE_KEY } from "../../../app/modules/auth";
import * as _ from "lodash";
import { graphql } from "babel-plugin-relay/macro";
import { DashboardTable_getLocationsWithMachineCountsQuery } from "./__generated__/DashboardTable_getLocationsWithMachineCountsQuery.graphql";
import { useLazyLoadQuery } from "react-relay";
import { Link } from "react-router-dom";
import { DownloadExcel } from "../ExcelHelper";
import { KTSVG } from "../../../_metronic/helpers";
import { DashboardTable_offlineMachinesQuery } from "./__generated__/DashboardTable_offlineMachinesQuery.graphql";
import $ from 'jquery';
import { DashboardTable_PilotMachinesQuery } from "./__generated__/DashboardTable_PilotMachinesQuery.graphql";

const totalPendingLocation = graphql`
  query DashboardTable_getLocationsWithMachineCountsQuery {
    getLocationsWithMachineCounts {
      id
      uuid
      customerId
      customer {
        id
        uuid
        name
      }
      name
      createdAt
      createdBy {
        id
        firstName
      }
    }
  }
`;

const getOfflineMachines = graphql`
  query DashboardTable_offlineMachinesQuery {
    offlineMachines {
      id
      uuid
      serialNumber
      name
      deploymentDate
      locationId
      deployingPersonId
      softwareVersion
      hardwareVersion
      connectionStatus
      createdAt
      updatedAt
      isActive
      remark
      lastHearbeatSyncAt
      lastSanitizeTime
      location{
        id
        name
        deploymentManager{
          id
          firstName
        }
      }
    }   
  }
`

const getPilotMachines = graphql`
  query DashboardTable_PilotMachinesQuery {
    pilotMachines {
      id
      uuid
      serialNumber
      name
      macAddress
      deploymentDate
      createdAt
      updatedAt
      isActive
      trialEndDate
      location{
        id
        name
        deploymentManager{
          id
          firstName
        }
        region {
          id
          name
        }
      }
      machineStatus {
        id
        name
      }
      
    }   
  }
`

const DashboardTable = () => {
  const [assignToMe, setAssignToMe] = React.useState<any>([]);
  const [createdByMe, setCreatedByMe] = React.useState<any>([]);
  const [tickets, setTickets] = React.useState<any>([]);
  const [locations, setLocations] = React.useState<any>([]);
  const [machines, setMachines] = React.useState<any>([]);
  const [pilotMachines, setPilotMachines] = React.useState<any>([]);
 
  const totalPendingLocationRecord =
    useLazyLoadQuery<DashboardTable_getLocationsWithMachineCountsQuery>(
      totalPendingLocation,
      {}
    );
    const totalOfflineMachines =
    useLazyLoadQuery<DashboardTable_offlineMachinesQuery>(
      getOfflineMachines,
      {}
    );
  
    const allPilotMachines =
    useLazyLoadQuery<DashboardTable_PilotMachinesQuery>(
      getPilotMachines,
      {}
    );

  const totalPendingLocationResult =
    totalPendingLocationRecord.getLocationsWithMachineCounts;
   
  const fetchData = async () => {
    try {
      let paginate = { page: 1, per_page: 10 };
      const lsValue: any = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
      const currentLogin = JSON.parse(lsValue);
      const currentLoginId = currentLogin?.user?.id ?? "";

      let filter = { status: { eq: "PENDING" } };
      const totalPendingTickets = await getTickets(filter, paginate);
      const tickets = totalPendingTickets?.data ?? [];
      setTickets(tickets);
    
      const locations = totalPendingLocationResult ?? [];
      setLocations(locations);

      const offlineMachines = totalOfflineMachines?.offlineMachines ?? []
      setMachines(offlineMachines);

      const pilotMachinesRecords = allPilotMachines?.pilotMachines ?? []
      setPilotMachines(pilotMachinesRecords);

      let assignFilter: any = {};
      assignFilter["status"] = { notin: ["COMPLETED", "ARCHIVED"] };
      assignFilter["assign_to_id"] = { eq: currentLoginId };
      const assignToMe = await getTickets(assignFilter, paginate);
      const assignTickets = assignToMe?.data ?? [];
      setAssignToMe(assignTickets);

      let createdFilter: any = {};
      createdFilter["status"] = { notin: ["COMPLETED", "ARCHIVED"] };
      createdFilter["created_by_id"] = { eq: currentLoginId };
      const createdByMe = await getTickets(createdFilter, paginate);
      const createdTickets = createdByMe?.data ?? [];
      setCreatedByMe(createdTickets);

    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  // Download excel
  const handleAssignDownloadExcel =() => {
    const excelRecords = [];
    const ticketsAssignTo = Array.from(assignToMe).map((ticket: any) => (ticket))
   
    for (const ticket of ticketsAssignTo) {

      const tempRecord = { 
        ticketId: ticket.uuid,
        machineName: ticket.machine.name,
        solutionNeeded : ticket.issuesReported?.map(
          (element: any) => element.name).join(",") + ","+
        ticket.machineServices?.map(
          (element: any) => element.name).join(",") + ","+
        ticket.operations?.map(
          (element: any) => element.name).join(",") + ","+
        ticket.marketingOrOthers?.map(
          (element: any) => element.name).join(",") + ","+
        ticket?.issueReportedOtherInput + 
        ticket?.machineServiceOtherInput  + 
        ticket?.marketingOtherInput + 
        ticket?.operationOtherInput,
       createdAt: ticket.createdBy?.firstName,
        daysWaiting:Moment().diff( Moment(Moment.unix(ticket.reportedAt / 1000)
                      .local()
                         .format("YYYY-MM-DD HH:mm:ss")
                       ),
                     "days"
                      ),
        priority: _.capitalize(_.lowerCase(ticket.priority)),
         status : _.capitalize(_.lowerCase(ticket.status))
      }
      excelRecords.push(tempRecord);
    }
    DownloadExcel(excelRecords, "SHEET_NAME", "TicketAssignToMe_list");
  };
  
  const handleDownloadExcel =() => {
    const excelRecords = [];
    const ticketsCreatedByMe = Array.from(createdByMe).map((ticket: any) => (ticket))
    for (const ticket of ticketsCreatedByMe) {
      const tempRecord = { 
        ticketId: ticket.uuid,
        machineName: ticket.machine.name,
        solutionNeeded : ticket.issuesReported?.map(
          (element: any) => element.name).join(",") + "," +
        ticket.machineServices?.map(
          (element: any) => element.name).join(",") + "," +
        ticket.operations?.map(
          (element: any) => element.name).join(",") + "," +
        ticket.marketingOrOthers?.map(
          (element: any) => element.name).join(",") + "," +
        ticket?.issueReportedOtherInput +
        ticket?.machineServiceOtherInput +
        ticket?.marketingOtherInput +
        ticket?.operationOtherInput,
        createdAt: ticket.createdAt,
        createdBy: ticket.createdBy?.firstName ,
        dayWaiting: Moment().diff( Moment(Moment.unix(ticket.reportedAt / 1000)
          .local()
        .format("YYYY-MM-DD HH:mm:ss")),"days"),
        assignTo:  ticket.assignTo?.firstName ,
        priority: _.capitalize(_.lowerCase(ticket.priority)),
        status:ticket.status,
       
      };
      excelRecords.push(tempRecord);
    }
    DownloadExcel(excelRecords, "SHEET_NAME", "TicketCreatedByMe_list");
  };
  
  const handleOfflineMachineDownloadExcel =() => {
    const excelRecords = [];
    const offileMachine = Array.from(machines).map((ticket: any) => (ticket))

    for (const machine of offileMachine) {
      const tempRecord = { 
       machineId: machine.uuid,
       machineName: machine.name,
       locationName:machine?.location?.name,
       deploymentManager:machine?.location?.deploymentManager?.firstName,
      LastOfflineDateAndTime:Moment.utc(machine?.lastHearbeatSyncAt).local().format("YYYY-MM-DD HH:mm:ss"),}
      excelRecords.push(tempRecord);
    }
    DownloadExcel(excelRecords, "SHEET_NAME", "Offilemachine_list");
  };

  const handlePilotMachineDownloadExcel =() => {
    const excelRecords = [];
    const offileMachine = Array.from(pilotMachines).map((ticket: any) => (ticket))

    for (const machine of offileMachine) {
      const tempRecord = { 
       machineId: machine.uuid,
       name: machine.name,
       'Serial no': machine.serialNumber,
       'Location':machine?.location?.name,
       'Region': machine?.location?.region?.name,
       'Machine Status': machine?.machineStatus?.name,
       'Deployment manager':machine?.location?.deploymentManager?.firstName,
      'Trial end date':machine?.trialEndDate,}
      excelRecords.push(tempRecord);
    }
    DownloadExcel(excelRecords, "SHEET_NAME", "Pilotmachine_list");
  };

  // Table sorting

 const TableCellValue = (row:any, index:any) => {
    return $(row).children("td").eq(index).text();
  }
  const TableComparer =  (index:any) => {
    return function({a, b}:any) {
      var val_a:any = TableCellValue(a, index);
      var val_b:any = TableCellValue(b, index);
      var result = ($.isNumeric(val_a) && $.isNumeric(val_b)) ? val_a - val_b : val_a.toString().localeCompare(val_b);
      return result;
    }
  };

  React.useEffect(() => {
    $(document).on("click", "table thead tr th:not(.no-sort)", function() {
      var table = $(this).parents("table");
      var rows = $(this).parents("table").find("tbody tr").toArray().sort(TableComparer($(this).index()));
      var dir = ($(this).hasClass("sort-asc")) ? "desc" : "asc";
      if (dir === "desc") {
        rows = rows.reverse();
      }
      for (var i = 0; i < rows.length; i++) {
        table.append(rows[i]);
      }
      table.find("thead tr th").removeClass("sort-asc").removeClass("sort-desc");
      $(this).removeClass("sort-asc").removeClass("sort-desc").addClass("sort-" + dir);
    })
    
    // eslint-disable-next-line
  }, [])

  return (
    <Suspense>
      <div>
        <div className="row mt-5">
          <div className="col-xl-6 col-lg-12 col-md-12 col-12">
            <div className="card mt-9  ">
              <div className="card-body w-100 p-lg-7 p-md-7 p-5 ">
                <div
                  className="table-responsive p-0 pb-2 fs-6 border-0  dashboardtable"
                  id="dashboard-table"
                >
                  <div className="row mb-6 justify-content-between">
                   <div className="col-xl-8 col-lg-8 col-md-8 col-12">
                    <div
                      className="btn-bg-light btn fs-2 fw-semibold text-start border-0"
                      data-bs-toggle="tooltip"
                      title="Service Tickets which are still pending"
                    >
                      Tickets Created By Me
                    </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-12 mt-2 pt-2 mt-md-0 pt-md-0 mt-lg-0 pt-lg-0">
                      <button
                        onClick={() => handleDownloadExcel()}
                        className="btn btn-bg-light btn-active-color-primary w-100 d-block fw-bold p-0 px-4 py-3 mb-3"
                      >
                        <KTSVG
                          path="/media/icons/duotune/files/fil021.svg"
                          className="svg-icon-4 me-2"
                        />
                        Export Excel
                      </button>
                    </div>
                    </div>
                  <table className="table dashboard-table dashboard-tables table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead className="table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                      <tr className="fs-6 fw-bold">
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary w-lg-50px">Id</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary" id="machine">Machine Name</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary" id="solution" colSpan={2}>Solution Needed</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary w-lg-100px">Created At</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary"id="day">Days Waiting</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary" id="assign">Assign To</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary" id="prioity">Priority</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary"id="status">Status</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {createdByMe.length > 0 ? (
                        Array.from(createdByMe).map((ticket: any) => (
                          <tr key={ticket.id}>
                          <td className="fs-6 w-lg-50px" data-title="Id">
                            <Link
                              data-bs-toggle="tooltip"
                              title="Click here to view ticket"
                              to={`/tickets/list/viewticket/${ticket.id}`}
                            >
                              {ticket.uuid ?? ""}
                            </Link>
                          </td>

                          <td className="fs-6" data-title="Machine Name" >
                            <Link
                              data-bs-toggle="tooltip"
                              title="Click here to view machine"
                              to={ticket.machine && ticket.machine.id ? `/machines/list/history/${ticket.machine.id}` : ""}
                            >
                              {ticket.machine?.name ?? ""}
                            </Link>
                          </td>
                          <td className="fs-6" data-title="Solution Needed" colSpan={2}>
                            <ul className="mb-0 pb-0 ps-4">
                              {ticket.issuesReported.length > 0 &&
                                ticket.issuesReported?.map(
                                  (element: any) => {
                                    return <li>{element.name}</li>;
                                  }
                                )}
                              {ticket.issueReportedOtherInput !== "" && (
                                <li className="fs-5 text-dark">
                                  {ticket.issueReportedOtherInput}
                                </li>
                              )}
                              {ticket.machineServices.length > 0 &&
                                ticket.machineServices?.map(
                                  (element: any) => {
                                    return <li>{element.name}</li>;
                                  }
                                )}
                              {ticket.machineServiceOtherInput !== "" && (
                                <li className="fs-5 text-dark">
                                  {ticket.machineServiceOtherInput}
                                </li>
                              )}
                              {ticket.operations.length > 0 &&
                                ticket.operations?.map((element: any) => {
                                  return <li>{element.name}</li>;
                                })}
                              {ticket.operationOtherInput !== "" && (
                                <li>{ticket.operationOtherInput}</li>
                              )}
                              {ticket.marketingOrOthers.length > 0 &&
                                ticket.marketingOrOthers?.map(
                                  (element: any) => {
                                    return <li>{element.name}</li>;
                                  }
                                )}
                              {ticket.marketingOtherInput !== "" && (
                                <li>{ticket.marketingOtherInput}</li>
                              )}
                            </ul>
                          </td>
                          <td className="fs-6 w-lg-100px " data-title="Created At">
                            <ul className="mb-0 pb-0 list-unstyled">
                              <li>
                                <span className="fw-bold me-1">
                                  Created By:
                                </span>
                                {ticket.createdBy?.firstName ?? ""}
                              </li>
                              <li>
                                <span className="fw-bold me-1">
                                  Created Time:
                                </span>
                                {Moment.utc(ticket.createdAt)
                                  .local()
                                  .format("YYYY-MM-DD HH:mm:ss")}
                              </li>
                            </ul>
                          </td>
                          <td className="fs-6" data-title="Days Waiting" >
                            {Moment().diff(
                              Moment(
                                Moment.unix(ticket.reportedAt / 1000)
                                  .local()
                                  .format("YYYY-MM-DD HH:mm:ss")
                              ),
                              "days"
                            )}
                          </td>
                          <td className="fs-6" data-title="Assign To" >
                            {ticket.assignTo?.firstName ?? ""}
                          </td>
                          <td className="fs-6" data-title="Priority">
                            {_.capitalize(_.lowerCase(ticket.priority))}
                          </td>
                          <td className="fs-6" data-title="Status">
                            {_.capitalize(_.lowerCase(ticket.status))}
                          </td>
                        </tr>
                        ))
                      ) : (
                        <>
                          <tr>
                            <td align="center" colSpan={4}>
                              No records found
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-12">
            <div className="card mt-9 ">
              <div className="card-body w-100 p-lg-7 p-md-7 p-5">
                <div className="table-responsive p-0 pb-2 fs-6 border-0  sorttable dashboardtable">
                  <div className="row mb-6 justify-content-between">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-12">
                      <div
                        className="btn-bg-light btn fs-2 fw-semibold text-start mb-6 border-0"
                        data-bs-toggle="tooltip"
                        title="Locations which don't have any machine assigned yet"
                      >
                        Tickets Assign To Me
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-12 mt-2 pt-2 mt-md-0 pt-md-0 mt-lg-0 pt-lg-0">
                      <button
                        onClick={() => handleAssignDownloadExcel()}
                        className="btn btn-bg-light btn-active-color-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3 "
                      >
                        <KTSVG
                          path="/media/icons/duotune/files/fil021.svg"
                          className="svg-icon-4 me-2"
                        />
                        Export Excel
                      </button>
                    </div>
                  </div>
                  <table className="table dashboard-table dashboard-tables table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead className="">
                      <tr className="fs-6 fw-bold">
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary w-lg-50px">Id</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary">Machine Name</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary" colSpan={1}>Solution Needed</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary w-lg-150px">Created At</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary">Days Waiting</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary">Priority</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary">Status</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {assignToMe.length > 0 ? (
                        Array.from(assignToMe)
                          .slice(0, 10)
                          .map((ticket: any) => (
                            <tr key={`assign-to-me-${ticket.id}`}>
                            <td className="fs-6 w-lg-50px" data-title="Id">
                              <Link
                                data-bs-toggle="tooltip"
                                title="Click here to view ticket"
                                to={`/tickets/list/viewticket/${ticket.id}`}
                              >
                                {ticket.uuid ?? ""}
                              </Link>
                            </td>
                            <td className="fs-6" data-title="Machine Name">
                              <Link
                                data-bs-toggle="tooltip"
                                title="Click here to view machine"
                                to={ticket.machine && ticket.machine.id ? `/machines/list/history/${ticket.machine.id}` : ""}
                              >
                                {ticket.machine?.name ?? ""}
                              </Link>
                            </td>
                            <td className="fs-6 ps-4" data-title="Solution Needed" colSpan={2}>
                              <ul className="mb-0 pb-0">
                                {ticket.issuesReported.length > 0 &&
                                  ticket.issuesReported?.map(
                                    (element: any) => {
                                      return <li>{element.name}</li>;
                                    }
                                  )}
                                {ticket.issueReportedOtherInput !== "" && (
                                  <li className="fs-5 text-dark">
                                    {ticket.issueReportedOtherInput}
                                  </li>
                                )}
                                {ticket.machineServices.length > 0 &&
                                  ticket.machineServices?.map(
                                    (element: any) => {
                                      return <li>{element.name}</li>;
                                    }
                                  )}
                                {ticket.machineServiceOtherInput !== "" && (
                                  <li className="fs-5 text-dark">
                                    {ticket.machineServiceOtherInput}
                                  </li>
                                )}
                                {ticket.operations.length > 0 &&
                                  ticket.operations?.map((element: any) => {
                                    return <li>{element.name}</li>;
                                  })}
                                {ticket.operationOtherInput !== "" && (
                                  <li>{ticket.operationOtherInput}</li>
                                )}
                                {ticket.marketingOrOthers.length > 0 &&
                                  ticket.marketingOrOthers?.map(
                                    (element: any) => {
                                      return <li>{element.name}</li>;
                                    }
                                  )}
                                {ticket.marketingOtherInput !== "" && (
                                  <li>{ticket.marketingOtherInput}</li>
                                )}
                              </ul>
                            </td>
                            <td className="fs-6 w-lg-150px" data-title="Created At">
                              <ul className="mb-0 pb-0 list-unstyled">
                                <li>
                                  <span className="fw-bold me-1">
                                    Created By:
                                  </span>
                                  {ticket.createdBy?.firstName ?? ""}
                                </li>
                                <li>
                                  <span className="fw-bold me-1">
                                    Created Time:
                                  </span>
                                  {Moment.utc(ticket.createdAt)
                                    .local()
                                    .format("YYYY-MM-DD HH:mm:ss")}
                                </li>
                              </ul>
                            </td>
                            <td className="fs-6" data-title="Days Waiting">
                              {Moment().diff(
                                Moment(
                                  Moment.unix(ticket.reportedAt / 1000)
                                    .local()
                                    .format("YYYY-MM-DD HH:mm:ss")
                                ),
                                "days"
                              )}
                            </td>
                            <td className="fs-6" data-title="Priority">
                              {_.capitalize(_.lowerCase(ticket.priority))}
                            </td>
                          
                            <td className="fs-6" data-title="Status">
                              {_.capitalize(_.lowerCase(ticket.status))}
                              {
                              ticket.status.toLowerCase() !== "archived" &&
                            
                              <Link
                                to={`/tickets/list/statuschange/${ticket.id}`}
                                className="btn align-self-center p-0 px-0 py-2 mt-lg-0 mt-md-0 mt-3"
                              >
                               
                                <button className="btn btn-secondary btn-status btn px-3 py-1  m-1 me-5">
                                  Status Change
                                </button>
                              
                              </Link>}
                            </td>
                          </tr>
                          ))
                      ) : (
                        <>
                          <tr>
                            <td align="center" colSpan={5}>
                              No records found
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-xl-6 col-lg-12 col-md-12 col-12">
            <div className="card mt-9 ">
              <div className="card-body w-100 p-lg-7 p-md-7 p-5">
                <div className="table-responsive p-0 pb-2 fs-6 border-0 dashboardtable">
                  <div
                    className="btn-bg-light btn fs-2 fw-semibold text-start mb-6 border-0"
                    data-bs-toggle="tooltip"
                    title="Service Tickets which are still pending"
                  >
                    Pending Service Tickets
                  </div>
                  <table  className="table sorttable dashboard-table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                      <tr className="fs-6 fw-bold">
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary w-lg-50px">Id</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary">Machine Name</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary">Location Name</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary">Solution Needed</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary">Created At</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary">Days Waiting</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tickets.length > 0 ? (
                        Array.from(tickets).map((ticket: any) => (
                          <tr key={`ticket-key-${ticket.id}`}>
                              <td data-title="id" className="w-lg-50px">
                                <Link
                                  data-bs-toggle="tooltip"
                                  title="Click here to view ticket"
                                  to={`/tickets/list/viewticket/${ticket.id}`}
                                >
                                  {ticket.uuid ?? ""}
                                </Link>
                              </td>

                              <td data-title="Location Name">
                                <Link
                                  data-bs-toggle="tooltip"
                                  title="Click here to view machine"
                                  to={ticket.machine && ticket.machine.id ? `/machines/list/history/${ticket.machine.id}` : ""}
                                >
                                  {ticket.machine?.name ?? ""}
                                </Link>
                              </td>
                              <td data-title="Location Name">
                                <Link
                                  data-bs-toggle="tooltip"
                                  title="Click here to view location"
                                  to={ticket.machine && ticket.machine.locationId ? `/locations/list/history/${ticket.machine.locationId}` : ''}
                                >
                                  {ticket.machine?.location?.name ?? ""}
                                </Link>
                              </td>
                              <td data-title="Solution Needed" >
                                <ul className="mb-0 pb-0 ps-4">
                                  {ticket.issuesReported.length > 0 &&
                                    ticket.issuesReported?.map(
                                      (element: any) => {
                                        return <li>{element.name}</li>;
                                      }
                                    )}
                                  {ticket.issueReportedOtherInput !== "" && (
                                    <li className="fs-5 text-dark">
                                      {ticket.issueReportedOtherInput}
                                    </li>
                                  )}
                                  {ticket.machineServices.length > 0 &&
                                    ticket.machineServices?.map(
                                      (element: any) => {
                                        return <li>{element.name}</li>;
                                      }
                                    )}
                                  {ticket.machineServiceOtherInput !== "" && (
                                    <li className="fs-5 text-dark">
                                      {ticket.machineServiceOtherInput}
                                    </li>
                                  )}
                                  {ticket.operations.length > 0 &&
                                    ticket.operations?.map((element: any) => {
                                      return <li>{element.name}</li>;
                                    })}
                                  {ticket.operationOtherInput !== "" && (
                                    <li>{ticket.operationOtherInput}</li>
                                  )}
                                  {ticket.marketingOrOthers.length > 0 &&
                                    ticket.marketingOrOthers?.map(
                                      (element: any) => {
                                        return <li>{element.name}</li>;
                                      }
                                    )}
                                  {ticket.marketingOtherInput !== "" && (
                                    <li>{ticket.marketingOtherInput}</li>
                                  )}
                                </ul>
                              </td>
                              <td data-title="Created At">
                                {Moment.utc(ticket.createdAt)
                                  .local()
                                  .format("YYYY-MM-DD HH:mm:ss")}
                              </td>
                              <td data-title="Days Waiting">
                                {Moment().diff(
                                  Moment(
                                    Moment.unix(ticket.reportedAt / 1000)
                                      .local()
                                      .format("YYYY-MM-DD HH:mm:ss")
                                  ),
                                  "days"
                                )}
                              </td>
                            </tr>
                        ))
                      ) : (
                        <>
                          <tr>
                            <td align="center" colSpan={4}>
                              No records found
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12  col-12">
            <div className="card mt-9  ">
              <div className="card-body w-100 p-lg-7 p-md-7 p-5  ">
                <div className="table-responsive p-0 pb-2 fs-6 border-0 dashboardtable">
                  <div
                    className="btn-bg-light btn fs-2 fw-semibold text-start mb-6 border-0"
                    data-bs-toggle="tooltip"
                    title="Locations which don't have any machine assigned yet"
                  >
                    Pending Jobs
                  </div>
                  <table className="table dashboard-table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                      <tr className="fs-6 fw-bold">
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary w-lg-100px">Location Id</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary">Customer Name</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary">Location Name</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary">Created At</th>
                        <th className="fs-6 text-muted cursor-pointer text-hover-primary">Created By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {locations.length > 0 ? (
                        Array.from(locations)
                          .slice(0, 10)
                          .map((location: any) => (
                            <tr key={`location-key-${location.id}`}>
                                <td data-title="Location Id" className="fs-6 w-lg-100px">
                                  <Link
                                    data-bs-toggle="tooltip"
                                    title="Click here to view location"
                                    to={`/locations/list/history/${location.id}`}
                                  >
                                    {location.uuid ?? ""}
                                  </Link>
                                </td>
                                <td data-title="Customer Name">
                                  <Link
                                    data-bs-toggle="tooltip"
                                    title="Click here to view customer"
                                    to={`/customers/list/history/${location.customer.id}`}
                                  >
                                    {location.customer?.name ?? ""}
                                  </Link>
                                </td>
                                <td data-title="Location Name">
                                  <Link
                                    data-bs-toggle="tooltip"
                                    title="Click here to view location"
                                    to={`/locations/list/history/${location.id}`}
                                  >
                                    {location.name}
                                  </Link>
                                </td>
                                <td data-title="Created At">
                                  {Moment.utc(location.createdAt)
                                    .local()
                                    .format("YYYY-MM-DD HH:mm:ss")}
                                </td>
                                <td data-title="Created By">
                                  {location.createdBy?.firstName ?? ""}
                                </td>
                              </tr>
                          ))
                      ) : (
                        <>
                          <tr>
                            <td align="center" colSpan={5}>
                              No records found
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="card mt-9">
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <div className="table-responsive p-0 pb-2 fs-6 border-0 sorttable dashboardtable">
              <div className="row mb-6 justify-content-between">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-12">
                <div
                  className="btn-bg-light btn fs-2 fw-semibold text-start mb-6 border-0"
                  data-bs-toggle="tooltip"
                  title="Service Tickets which are still pending"
                >
                  Offline Machines
                  
                </div>
                <br/>
                  <span className="text-muted mt-1 fw-semibold fs-7">List of machines not connected for 7 days.</span>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-12 mt-2 pt-2 mt-md-0 pt-md-0 mt-lg-0 pt-lg-0">
                <button
                  onClick={() => handleOfflineMachineDownloadExcel()}
                  className="btn btn-bg-light btn-active-color-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3 "
                >
                  <KTSVG
                    path="/media/icons/duotune/files/fil021.svg"
                    className="svg-icon-4 me-2"
                  />
                  Export Excel
                </button>
                </div>
              </div>
                <table   className="table  dashboard-table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <thead>
                    <tr className="fs-5 fw-bold">
                      <th className="fs-5 text-muted cursor-pointer text-hover-primary w-lg-100px" >Id</th>
                      <th className="fs-5 text-muted cursor-pointer text-hover-primary">Machine Name</th>
                      <th className="fs-5 text-muted cursor-pointer text-hover-primary">Location Name</th>
                      <th className="fs-5 text-muted cursor-pointer text-hover-primary">Deployment Manager</th>
                      <th className="fs-5 text-muted cursor-pointer text-hover-primary">Last Online Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {machines.length > 0 ? (
                      Array.from(machines).map((machine: any) => (
                        <tr className="dashboard-body" key={`machine-key-${machine.id}`}>
                        <td data-title="id" className=" w-lg-100px">
                          <Link
                            data-bs-toggle="tooltip"
                            title="Click here to view machine"
                            to={`/machines/list/history/${machine.id}`}
                          >
                            {machine.uuid ?? ""}
                          </Link>
                        </td>

                        <td data-title="Machine Name">
                          <Link
                            data-bs-toggle="tooltip"
                            title="Click here to view machine"
                            to={`/machines/list/history/${machine.id}`}
                          >
                            {machine?.name ?? ""}
                          </Link>
                        </td>
                        <td data-title="Location Name">
                          <Link
                              data-bs-toggle="tooltip"
                              title="Click here to view location"
                              to={`/locations/list/history/${machine?.locationId}`}
                            >
                            {machine?.location?.name ?? ""}
                          </Link>
                        </td>
                        <td data-title="DeploymentManager">
                          {machine?.location?.deploymentManager?.firstName ?? ""}
                        </td>
                        <td data-title="Last Offline Date & Time">
                           {machine.lastHearbeatSyncAt ? 
                           Moment.utc(machine.lastHearbeatSyncAt)
                                  .local()
                                  .format("YYYY-MM-DD HH:mm:ss") :""}
                       </td>
                      </tr>
                      ))
                    ) : (
                      <>
                        <tr>
                          <td align="center" colSpan={4}>
                            No records found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>


        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="card mt-9">
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <div className="table-responsive p-0 pb-2 fs-6 border-0 sorttable dashboardtable">
              <div className="row mb-6 justify-content-between">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-12">
                <div
                  className="btn-bg-light btn fs-2 fw-semibold text-start mb-6 border-0"
                  data-bs-toggle="tooltip"
                  title="Service Tickets which are still pending"
                >
                  Pilot Machines
                </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-12 mt-2 pt-2 mt-md-0 pt-md-0 mt-lg-0 pt-lg-0">
                <button
                  onClick={() => handlePilotMachineDownloadExcel()}
                  className="btn btn-bg-light btn-active-color-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3 "
                >
                  <KTSVG
                    path="/media/icons/duotune/files/fil021.svg"
                    className="svg-icon-4 me-2"
                  />
                  Export Excel
                </button>
                </div>
              </div>
                <table   className="table  dashboard-table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <thead>
                    <tr className="fs-5 fw-bold">
                      <th className="fs-5 text-muted cursor-pointer text-hover-primary w-lg-100px" >Id</th>
                      <th className="fs-5 text-muted cursor-pointer text-hover-primary">Machine</th>
                      <th className="fs-5 text-muted cursor-pointer text-hover-primary">Location</th>
                      <th className="fs-5 text-muted cursor-pointer text-hover-primary">Region</th>
                      <th className="fs-5 text-muted cursor-pointer text-hover-primary">Machine Status</th>
                      <th className="fs-5 text-muted cursor-pointer text-hover-primary">Deployment Manager</th>
                      <th className="fs-5 text-muted cursor-pointer text-hover-primary">Trial End Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pilotMachines.length > 0 ? (
                      Array.from(pilotMachines).map((machines: any) => (
                        <tr className="dashboard-body" key={`pilot-machine-key-${machines.id}`}>
                            <td data-title="id" className=" w-lg-100px">
                              <Link
                                data-bs-toggle="tooltip"
                                title="Click here to view machine"
                                to={`/machines/list/history/${machines.id}`}
                              >
                                {machines.uuid ?? ""}
                              </Link>
                            </td>

                            <td data-title="Machine">
                              <Link
                                data-bs-toggle="tooltip"
                                title="Click here to view machine"
                                to={`/machines/list/history/${machines.id}`}
                              >
                                {machines?.serialNumber ?? ""}
                              </Link>
                            </td>
                            <td data-title="Location">
                              <Link
                                  data-bs-toggle="tooltip"
                                  title="Click here to view location"
                                  to={`/locations/list/history/${machines?.locationId}`}
                                >
                                {machines?.location?.name ?? ""}
                              </Link>
                            </td>
                            <td data-title="Region">
                              {machines?.location?.region?.name ?? ""}
                            </td>
                            <td data-title="MachineStatus">
                              {machines?.machineStatus?.name ?? ""}
                            </td>
                            <td data-title="DeploymentManager">
                              {machines?.location?.deploymentManager?.firstName ?? ""}
                            </td>
                            <td data-title="Last Offline Date & Time">
                               {machines.trialEndDate}
                           </td>
                          </tr>
                      ))
                    ) : (
                      <>
                        <tr>
                          <td align="center" colSpan={4}>
                            No records found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      </div>
    </Suspense>
  );
};
export default DashboardTable;
