import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

interface createScheduleEventInput {
  regionalManagerId: string;
  eventType: string;
  locationId: string;
  scheduleStartDateTime: Date;
  scheduleEndDateTime: Date;
  jobType?: string;
  referenceId?: string;
  machineId?: string;
  workOrderFirstName?: string;
  workOrderLastName?: string;
}

export async function createScheduleEvent(input: createScheduleEventInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation createScheduleEvent (
          $input:CreateScheduleEventInput!
        ) {
          createScheduleEvent(
            createScheduleEventInput:$input
          ) {
              id
              locationId
              machineId
              regionalManagerId
              referenceId
              scheduledById
              canceledById
              createdAt
              updatedAt
            }
        }
      `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getScheduleEvents() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query {
                getScheduleEvents {
                  id
                  scheduleStartDateTime
                  scheduleEndDateTime
                  eventType
                  updatedAt
                  createdAt
                  referenceId
                  location{
                    id,
                    name
                  }
                  reference {
                  ... on TicketObjectType {
                      id
                      hashId
                      uuid
                      ticketType
                      machineId
                      reportedAt
                      scheduleStartDate
                      scheduleEndDate
                      closingDateAndTime
                      ticketStatus: status
                      machine {
                        id
                        name
                        location{
                          id
                          name
                          customer{
                            id
                            name
                          }
                        }
                      }
                      issuesReported {
                        id
                        name 
                      }
                      daysOpen
                      scheduleStartDate
                      scheduleEndDate
                      travelingStartTime
                      travelingEndTime
                      workStartTime
                      parentId
                      ticketSourceId
                      reportedAt
                      assignToId
                      assignTo {
                        id
                        firstName
                        lastName
                      }
                    }
                    ... on LogJobsObjectType {
                      id
                      jobType 
                      logStatus: status
                    }
                    ... on WorkOrderObjectType {
                      id
                      firstName
                      workOrderStatus: status
                      lastName
                      locationId
                    }
                  }
                  regionalManager {
                    id
                    firstName
                    lastName
                  }
                }
            }
            `,
    },
  });
  return data?.data?.data?.getScheduleEvents;
}

export async function cancelScheduleEvent(scheduleEventId: string) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
    mutation cancelScheduleEvent(
      $scheduleEventId:String!
    ){
      cancelScheduleEvent(
        scheduleEventId: $scheduleEventId
      )
    }
    `,
      variables: {
        scheduleEventId,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}


interface reScheduleEventInput {
  id: string;
  assignToId: string;
  scheduleStartDate: string;
  scheduleEndDate:string;
}

export async function reScheduleEvent(input: reScheduleEventInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation reScheduleEvent (
          $input:ReScheduleEventInput!
        ) {
          reScheduleEvent(
            reScheduleEventInput:$input
          )
        }
      `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
