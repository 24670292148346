import React, { Suspense } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { KTSVG } from "../../../../_metronic/helpers";
import clsx from "clsx";
import MachineOverview from "./Tabs/MachineOverview";
import { graphql } from 'babel-plugin-relay/macro';
import TicketsOverview from "./Tabs/TicketsOverview";
import LocationHistoryOverview from './Tabs/LocationHistoryOverview'
import { useLazyLoadQuery } from 'react-relay';
import { MachineHistory_getmachineQuery } from './__generated__/MachineHistory_getmachineQuery.graphql';
import { EditMachine } from '../EditMachine';
import { BarCodeModal } from '../BarCodeModal'
import { ChangeLocationModal } from '../ChangeLocationModal';
import AssignLocationModal from '../AssignLocationModal'
import BlendOverview from "./Tabs/BlendOverview";
import { Toaster } from "react-hot-toast";
import MachineRevision from "./Tabs/MachineRevision";
import { getHistory } from "../../../models/_machine";

export const getMachineListById = graphql`
query MachineHistory_getmachineQuery($id:String!){
  getmachine(id:$id){
    id
    uuid
    serialNumber
    name
    macAddress
    deploymentDate
    locationId
    deployingPersonId
    machineStatusId
    machineStatus {
      id
      name
    }
    softwareVersion
    hardwareVersion
    connectionStatus
    lastHearbeatSyncAt
    lastSanitizeTime
    createdById
    createdAt
    updatedById
    updatedAt
    isActive
    isPilot
    isPolyfuses
    tubeShieldId
    trialEndDate
    lastSanitizerReplacedAt
    lastFilterReplacedAt
    remark
    noteRelatedToMechanicalHardwareVersion
    retiredAt
    blenderId
    boardId
    cupHolderId
    retiredById
    isRetired
    metas{
      metaKey
      metaValue
      createdAt
      updatedAt
    }
    tubeShield {
      id
      name
      description
    }
    createdBy {
      id
      firstName
    }
    updatedBy {
      id
      firstName
    }
    deployingPerson {
      id
      firstName
    }
    location {
      id
      uuid
      customerId
      name
      address
      regionId
      contactName
      contactPhone
      marketSegmentId
      deploymentManagerId
      locationTypeId
      targetLaunchDate
      distributorId
      createdById
      createdAt
      isActive
    }
    blender {
      id
      versionNo
      title
    }
    board {
      id
      versionNo
      title
    }
    cupHolder {
      id
      title
    }
    retiredBy {
      id
      firstName
      lastName
      email
  }
    tickets{
      id
      uuid
      machine{
        id
        name
      }
      ticketType
      serviceType
      comment
      priority
      ticketSource{
        id
        name
      }
      createdBy{
        id
        firstName
      }
      reportedAt
      closingDateAndTime
      assignTo{
        id
        firstName
      }
      travelTime
      trafficRate
      timeSpentOnTask
      status
      staffEnthusiasm
    }
    locationHistory {
      id
      locationId
      machineId
      createdAt
      updatedAt
      isAssign
      location{
        id
        name
        uuid
        address
        city
        isActive
        marketSegment {
          id
          name
        }
        deploymentManager {
          id
          firstName
          lastName
        }
        locationType {
          id
          name
        }
        region {
          id
          name
        }
        distributor{
          id
          name
        }
        targetLaunchDate
        createdAt
      }
    }
  }
}
`
export const MachineHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [machines, setMachines] = React.useState<any>({});
  const [locations, setLocations] = React.useState<any>({});
  const [tickets, setTickets] = React.useState<any>([]);
  const [tab, setTab] = React.useState("Overview");
  const [locationHistory, setLocationHistory] = React.useState<any>({})
  const [machineHistory, setMachineHistory] = React.useState<any>({})
  const [machineEditModal, setMachineEditModal] = React.useState(false);
  const [barCodeModal, setBarCodeModal] = React.useState(false)
  const [changeLocationModal, setChangeLocationModal] = React.useState(false)
  const [assignLocationModalOpen, setAssignLocationModalOpen] = React.useState(false)
  const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState<any>(null);
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(50);

  const splittedRoute = location.pathname.split("/").reverse()[0];
  if (!splittedRoute) {
    navigate("/machines/list");
  }
  const machineRecord = useLazyLoadQuery<MachineHistory_getmachineQuery>(
    getMachineListById,
    {
      id: splittedRoute ?? ""
    }, refreshedQueryOptions ?? {})
  const result: any = machineRecord?.getmachine ?? []

  const currentMachineId = result.id
  const fetchData = async () => {
    if (result == null) {
      navigate("/machines/list");
    } else {
      setMachines(result);
      const location = result.location ?? {};
      setLocations(location);

      const totalTickets = result.tickets ?? [];
      setTickets(totalTickets);
      const CurrentlocationHistory = result.locationHistory ?? []

      setLocationHistory(CurrentlocationHistory)
    }
  };
  const refresh = React.useCallback(() => {
    setRefreshedQueryOptions((prev: any) => ({
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: "store-and-network",
    }));
  }, []);

  const handleCloseMachine = () => {
    setMachineEditModal(false)
    refresh()
  }
  const handleCloseBarCode = () => {
    setBarCodeModal(false)
    refresh()
  }
  const handleCloseChangeLocation = () => {
    setChangeLocationModal(false)
    refresh()
  }
  const handleCloseAssignLocation = () => {
    setAssignLocationModalOpen(false)
    refresh()
  }

  const fetchMachineHistory = async () => {
    const machineHistoryPayload = {
      inputs:{id: currentMachineId,type: "Machine"},
      pagination:{page,per_page:perPage}
    }
    const machineHistory = await getHistory(machineHistoryPayload.inputs,machineHistoryPayload.pagination)
    setMachineHistory(machineHistory);
  }

  React.useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  React.useEffect(() => {
    fetchMachineHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page,perPage,result])

  return (
    <Suspense>
      <div>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Machine History - {machines.uuid}
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item text-muted">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                  <a
                    href="/machines/list"
                    className="text-muted text-hover-primary ps-2 fs-5"
                  >
                    Machines List
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item fs-5 text-dark">
                  Machine History
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mx-xl-4 mt-9">
          <div className="card">
            <div className="card-header cursor-pointer border-bottom-0 py-4">
              <div className="card-title m-0">
                <h3 className="fw-semibold m-0">Machine Details - {result.uuid}</h3>
              </div>
              <div>
                <button data-bs-toggle="tooltip" title="Click here to edit machine"
                  onClick={() => { setMachineEditModal(true) }}
                  className="btn btn-secondary btn px-5 py-3 m-1"
                >
                  <i className="fa fa-pencil fs-4" ></i>
                </button>
                <button data-bs-toggle="tooltip" title="Click here to view barcode" onClick={() => { setBarCodeModal(true) }} className="btn btn-secondary btn px-5 py-3 m-1">
                  <i className="fa fa-barcode fs-4" ></i>
                </button>
                {result.locationId == null && (
                  <button data-bs-toggle="tooltip" title="Click here to assign location" onClick={() => { setAssignLocationModalOpen(true) }} className="btn btn-secondary btn px-5 py-3 m-1">
                    <i className="fa-solid fa-location-dot fs-4" ></i>
                  </button>
                )}
                {result.locationId !== null &&
                  (
                    <button data-bs-toggle="tooltip" title="Click here to change location" onClick={() => { setChangeLocationModal(true) }} className="btn btn-secondary btn px-5 py-3 m-1">
                      <i className="fa fa-exchange fs-4" ></i>
                    </button>)}
                    <Link
                      to={`/tickets/create?machine_id=${result.id}&label=${result.location?.name ?? ""} (${result?.serialNumber ?? ""})`}
                      className="btn align-self-center p-0 mt-lg-0 mt-md-0 mt-3"
                    >
                      <button data-bs-toggle="tooltip" title="Click here to create ticket" className="btn btn-secondary btn px-5 py-3 m-1">
                      <i className="fa fa-ticket f-6"></i>
                      </button>
                    </Link>
              </div>
            </div>
          </div>
        </div>
        {machineEditModal && (
          <EditMachine machineEditModal={machineEditModal}
            handleCloseMachine={handleCloseMachine}
            currentMachineId={currentMachineId}
          />
        )}
        {barCodeModal && (
          <BarCodeModal 
            barCodeModal={barCodeModal}
            handleCloseBarCode={handleCloseBarCode}
            machineName={machines.name}
            machineSerialNumber={machines.serialNumber}
          />
        )}
        {changeLocationModal && (
          <ChangeLocationModal
            changeLocationModal={changeLocationModal}
            handleCloseChangeLocation={handleCloseChangeLocation}
            currentMachineId={currentMachineId}
          />
        )}
        {assignLocationModalOpen && (
          <AssignLocationModal
            assignLocationModalOpen={assignLocationModalOpen}
            handleCloseAssignLocation={handleCloseAssignLocation}
            currentMachineId={currentMachineId}
          />
        )}

        <div className="card mt-9 mx-xl-4">
          <div className="card-body p-9 pb-0">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-8 col-md-6 col-12flex-wrap mb-2">
                <div className="d-flex flex-column">
                  <div className="align-items-center mb-2">
                    <div className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                      {machines.name}
                    </div>
                  </div>
                  <div className="fw-bold fs-6 mb-3 ms-2 pe-2 mt-2">
                    <div className="d-lg-flex d-md-flex d-block align-items-center text-gray-500 mb-3 mt-3 mt-lg-0 mt-md-0">
                      <i className="fa fa-blender me-3 fs-6"></i>
                      Machine Name :
                      <div className="ms-3 text-gray-800">{machines.name}</div>
                    </div>
                    <div className="d-lg-flex d-md-flex d-block align-items-center text-gray-500 mb-3 mt-3 mt-lg-0 mt-md-0">
                      <i className="fa fa-key me-3 fs-6"></i>
                      Serial Number :
                      <div className="ms-3 text-gray-800">
                        {machines.serialNumber}
                      </div>
                    </div>
                    <div className="d-lg-flex d-md-flex d-block align-items-center text-gray-500 mb-3 mt-3 mt-lg-0 mt-md-0">
                      <i className="fa fa-address-card me-3 fs-6"></i>
                      Mac Address :
                      <div className="ms-3 text-gray-800">
                        {machines.macAddress}
                      </div>
                    </div>

                    <div className="d-lg-flex d-md-flex d-block align-items-center text-gray-500 mb-3 mt-3 mt-lg-0 mt-md-0">
                      <i className="fa fa-plane-lock me-3 fs-6"></i>
                      Is Pilot? :
                      <div className="ms-3 text-gray-800">
                        {machines.isPilot ? 'Yes' : 'No'}
                      </div>
                    </div>

                    {machines.isPilot ? <div className="d-lg-flex d-md-flex d-block align-items-center text-gray-500 mb-3 mt-3 mt-lg-0 mt-md-0">
                      <i className="fa fa-calendar me-3 fs-6"></i>
                      Trial End Date :
                      <div className="ms-3 text-gray-800">
                        {machines.trialEndDate}
                      </div>
                    </div> : ''}
                    {machines?.updatedBy ? <div className="d-lg-flex d-md-flex d-block align-items-center text-gray-500 mb-3 mt-3 mt-lg-0 mt-md-0">
                      <i className="fa fa-user me-3 fs-6"></i>
                      Last Updated By :
                      <div className="ms-3 text-gray-800">
                        {machines.updatedBy.firstName}
                      </div>
                    </div> : ''}
                  </div>
                </div>
                {result.locationId !== null ? (<>
                  <div className="text-gray-800 fs-4 fw-bolder text-dark mb-4">
                    Current Location  :
                  </div>
                  <Link

                    to={`/locations/list/history/${locations.id}`}
                    className="fw-bold ms-2 d-lg-flex d-md-flex d-block align-items-center text-gray-500 fs-6 mb-3 mt-3 mt-lg-0 mt-md-0"
                  >
                    <i className="fa fa-map-marker me-3 fs-6"></i>
                    Location Name :
                    <div className="ms-3 text-primary">
                      {locations.name} - {locations.uuid}
                      <i className="fa fa-external-link ms-3"></i>
                    </div>
                  </Link>
                  <Link

                    to={`/locations/list/history/${locations.id}`}
                    className="fw-bold ms-2 fs-6 d-lg-flex d-md-flex d-block align-items-center text-gray-500 mb-3 mt-3 mt-lg-0 mt-md-0"
                  >
                    <i className="fa fa-address-card me-3 fs-6"></i>
                    Location Address :
                    <div className="ms-3 text-gray-800 text-hover-primary">
                      {locations.address}
                    </div>
                  </Link>
                </>) : ("")}
              </div>

              <div className="col-lg-4 col-md-6 col-12 w-sm-25">
                <div className="border border-hover-primary border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3">
                  <div className="d-flex align-items-center">
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr066.svg"
                      className="svg-icon-3 svg-icon-success me-2"
                    />
                    <div className="fs-2 fw-bolder">{tickets.length ?? 0}</div>
                  </div>

                  <div className="fw-bold fs-6 text-gray-400">Tickets</div>
                </div>
              </div>
            </div>
            <div className="overflow-auto">
              <ul
                className="nav nav-stretch nav-line-tabs mt-5 border-transparent flex-nowrap fs-5 fw-bolder"
                role="tablist"
              >
                <li className="nav-item me-4">
                  <div
                    className={clsx(`nav-link cursor-pointer pb-5`, {
                      active: tab === "Overview",
                    })}
                    onClick={() => setTab("Overview")}
                    role="tab"
                  >
                    Overview
                  </div>
                </li>
                <li className="nav-item me-4">
                  <div
                    className={clsx(`nav-link cursor-pointer pb-5`, {
                      active: tab === "Tickets",
                    })}
                    onClick={() => setTab("Tickets")}
                    role="tab"
                  >
                    Tickets
                  </div>
                </li>
                <li className="nav-item me-4">
                  <div
                    className={clsx(`nav-link cursor-pointer pb-5`, {
                      active: tab === "LocationHistory",
                    })}
                    onClick={() => setTab("LocationHistory")}
                    role="tab"
                  >
                    Location History
                  </div>
                </li>
                <li className="nav-item me-4">
                  <div
                    className={clsx(`nav-link cursor-pointer pb-5`, {
                      active: tab === "BlendLogs",
                    })}
                    onClick={() => setTab("BlendLogs")}
                    role="tab"
                  >
                    Blend Logs
                  </div>
                </li>
                <li className="nav-item me-4">
                  <div
                    className={clsx(`nav-link cursor-pointer pb-5`, {
                      active: tab === "Revisions",
                    })}
                    onClick={() => setTab("Revisions")}
                    role="tab"
                  >
                    Revisions
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="tab-content pt-3">
          <div className={clsx("tab-pane", { active: tab === "Overview" })}>
            <MachineOverview machines={machines} />
          </div>
          <div className={clsx("tab-pane", { active: tab === "Tickets" })}>
            <TicketsOverview tickets={tickets} />
          </div>
          <div className={clsx("tab-pane", { active: tab === "LocationHistory" })}>
            <LocationHistoryOverview locationHistory={locationHistory} />
          </div>
          <div className={clsx("tab-pane", { active: tab === "BlendLogs" })}>
            <BlendOverview />
          </div>
          <div className={clsx("tab-pane", { active: tab === "Revisions" })}>
            <MachineRevision machineHistory={machineHistory} page = {page} setPage = {setPage} perPage = {perPage}/>
          </div>
        </div>
      </div>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
    </Suspense>
  );
};
