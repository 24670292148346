import * as Yup from "yup";

const workOrderInitialValues = {
  installerId: "",
  closeUpMedia: [],
  storeSetUpMedia: [],
  drainSetUpMedia: [],
  serialNumberMedia: [],
  comment: "",
  machineId1: "",
  isSecondMachineInstalled: false,
  machineId2: "",
  firstName: "",
  installerName: "",
  lastName: "",
  date: "",
  signature: "",
  storeContactName:"",
  storeContactPhoneNumber:"",
  storeContactRole:"",
  installationSteps: {
    freezerInstalled: null,
    footerInstalled: null,
    sanitizerInstalled: null,
    headerInstalled: null,
    boosterBarInstalled: null,
    drainageInstalled: null,
    internetConnected: null,
    flavorTagsInstalled: null,
    serviceStickerInstalled: null,
    oneTwoThreeStickersInstalled: null,
    palmTree: null,
    filledFreezer: null,
    pressureWithWeighingScale:null
  },
  testPerformance: {
    blendedFourSmoodies: null,
    sanitizer: null,
    getStatus: null,
    drainTest: null,
    waterPressureAbove3100: null,
  },
  staffTraining: {
    makingSmoodies: null,
    benefitsOfSmoodi: null,
    accessingServiceMenu: null,
    cleaningWipers: null,
    checkingSanitizer: null,
    smoodiStore:null,
    squeezingCupsBeforePuttingInFreezer:null,
  },
  materialInstallation: {
    boosterPump: null,
    windowDecal: null,
    doorDecal: null,
    palmTree: null,
    neonSign: null,
    smoodiMat: null,
    videoOnTv: null,
    videoOnOutdoorSign: null,
    freezerMagnet: null,
    machineSideSticker: null,
    outdoorFlag: null,
    rollUpBanner: null,
  },
  requiredTask: [
    {
      action: "",
      priority: "",
      dueDate: "",
    },
  ],
};

const workOrderValidationSchema = Yup.object()
  .shape({
    installerId: Yup.string().required(
      "Installer person is required"
    ),
    installerName: Yup.string().when("installerId", {
    is: (value:any) => {
      return !value || value === "other";
    },
    then: Yup.string().required("Installer person name is required"),
    otherwise: Yup.string().notRequired(),
    }),
    date: Yup.date().required("Date is required"),
    firstName: Yup.string(),
    machineId1: Yup.string().required('Machine one is required'),
    machineId2: Yup.string().when(["isSecondMachineInstalled"], {
      is: (isSecondMachineInstalled: boolean) =>
      isSecondMachineInstalled == true,
      then: Yup.string().required(
        "Machine two is required"
      ),
      otherwise: Yup.string().notRequired(),
    }),
  })
  .test("name-fields", "Name and Surname are required", function (values) {
    const {firstName, lastName} = values;
    if (!firstName || !lastName) {
      return this.createError({
        path: firstName ? "lastName" : "firstName",
        message: firstName ? "Surname is required" : "Name is required",
      });
    }
    return true;
  });

const capitalizeWord = (str: string) => {
  return str
    .replaceAll("_", " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(" ")
    .replace("One Two Three", "1,2,3")
    .replace("Four", "4")
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
};

const selectPriorityDB: any = [
  {
    id: "HIGH",
    name: "High",
  },
  {
    id: "LOW",
    name: "Low",
  },
];

const selectMachineDB: any = [
  {
    id: true,
    name: "Yes",
  },
  {
    id: false,
    name: "No",
  },
];

const WorkOrderInstallationSteps = [
  {
    id: "OK",
    name: "Ok",
  },
  {
    id: "REQUIRED_ACTION",
    name: "Required Action",
  },
  {
    id: "NOT_INCLUDE",
    name: "Not Included",
  },
];

const WorkOrderTestPerformed = [
  {
    id: "PASSED",
    name: "Passed",
  },
  {
    id: "FAIL",
    name: "Fail",
  },
  {
    id: "NOT_CHECKED",
    name: "Not Checked",
  },
];

export type MediaFile = {
  name: string;
  type: string;
  content: string;
};

const validateObject = (obj: any) => {
  let result = false;
  Object.keys(obj).map((key) => {
    if (obj[key]) {
      result = true;
    }
    return '';
  });
  return result;
};

const validateRequiredTasks = (data: any) => {
  const result: any = [];
  data.map((x: any) => {
    if (x['action'] && x['priority'] && x['dueDate'] && x['newTaskMachineId']) {
      result.push(x);
    }
    return '';
  });
  return result;
};

export {
  workOrderInitialValues,
  workOrderValidationSchema,
  capitalizeWord,
  selectPriorityDB,
  selectMachineDB,
  WorkOrderInstallationSteps,
  WorkOrderTestPerformed,
  validateObject,
  validateRequiredTasks
};
