import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateCustomerAccountInput {
  name: string;
  email: string;
  password: string;
  customerId: string;
  roleId: string;
  locationId?: string;
}

export async function createCustomerAccount(input: CreateCustomerAccountInput) {
  let data: any;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
      mutation createCustomerAccount (
        $input: CreateCustomerAccountInput!
        ){
        createCustomerAccount(
            createCustomerAccountInput: $input
          ) { 
            id
            name
            email
            password
            locationId
            customerId
            roleId
            portalUserId
            createdById
            updatedById
            deletedById
            updatedAt
            createdAt
            deletedAt
        }
      }
      `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getCustomerAccounts(
  where: {} = {},
  pagination: {} | undefined = undefined
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query getCustomerAccounts(  
                $where: CustomerAccountsResolver_GetCustomerAccounts_FilterInputType
                $paginate:PaginatorArgs
              ) {
                  getCustomerAccounts(where: $where ,paginate: $paginate) {
                      data {
                              id
                              name
                              email
                              password
                              locationId
                              customerId
                              roleId
                              portalUserId
                              createdById
                              updatedById
                              deletedById
                              updatedAt
                              createdAt
                              deletedAt
                              createdBy{
                                id,
                                firstName
                              }
                              updatedBy{
                                id,
                                firstName
                              }
                              location{
                                id
                                name
                              }
                              customer{
                                id
                                name
                                
                              }
                              customerRole{
                                id
                                slug
                                name
                              }
                      }
                      totalPages
                      total
                      perPage
                      page 
                    }
            }
            `,
      variables: { where, paginate: pagination },
    },
  });
  return data?.data?.data?.getCustomerAccounts;
}

interface UpdateCustomerAccountInput {
  name?: string;
  email?: string;
  customerId?: string;
  roleId?: string;
  locationId?: string;
}

export async function updateCustomerAccount(
  id: string,
  input: UpdateCustomerAccountInput
) {
  let data: any;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
      mutation updateCustomerAccount (
          $id: String!
          $input: UpdateCustomerAccountInput!
        ){
         updateCustomerAccount(
            id: $id
            updateCustomerAccountInput: $input
          ) { 
            id
            name
            email
            password
            locationId
            customerId
            roleId
            portalUserId
            createdById
            updatedById
            deletedById
            updatedAt
            createdAt
            deletedAt
        }
      }
      `,
      variables: {
        id,
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

interface ChangeCustomerAccountPasswordInput {
  password: string;
}

export async function changeCustomerAccountPassword(
  id: string,
  input: ChangeCustomerAccountPasswordInput
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
    mutation changeCustomerAccountPassword(
      $id:String!
      $input: ChangeCustomerAccountPasswordInput!
    ){
      changeCustomerAccountPassword(
          id: $id
          changeCustomerAccountPasswordInput: $input
      )
    }
    `,
      variables: {
        id,
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function deleteCustomerAccount(id: string) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
    mutation deleteCustomerAccount(
      $id:String!
    ){
      deleteCustomerAccount(
          id: $id
      )
    }
    `,
      variables: {
        id,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getCustomerRoleList() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          query {
            getCustomerRoleList {
              id
              name
              slug
              createdAt
              updatedAt
            }
          }
          `,
      variables: {},
    },
  });
  return data?.data?.data?.getCustomerRoleList;
}

export async function getCustomerAccountById(id: string) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          query getCustomerAccountById($id: String!) {
            getCustomerAccountById(id: $id) {
                id
                name
                email
                password
                locationId
                customerId
                roleId
                portalUserId
                createdById
                updatedById
                deletedById
                updatedAt
                createdAt
                deletedAt
                createdBy{
                  id,
                  firstName
                }
                updatedBy{
                  id,
                  firstName
                }
                location{
                  id
                  name
                }
                customer{
                  id
                  name
                }
                customerRole{
                  id
                  slug
                  name
                }
            }
          }
          `,
      variables: {
        id
      },
    },
  });
  return data?.data?.data?.getCustomerAccountById;
}
