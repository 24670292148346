import { useIntl } from "react-intl";
import { MenuItem } from "./MenuItem";
import { MenuInnerWithSub } from "./MenuInnerWithSub";
import { MegaMenu } from "./MegaMenu";

export function MenuInner() {
  const getPermissions: any = localStorage.getItem("permissions");
  const permissions = getPermissions || [];
  const intl = useIntl();
  return (
    <>
      <MenuItem
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        to="/dashboard"
      />
      <MenuInnerWithSub
        title="Pages"
        to="/"
        menuPlacement="bottom-start"
        menuTrigger="click"
        menuArrow={true}
      >
        {/* PAGES */}
        {/* {permissions?.includes("view-quicksight-dashboard") === true && (
          <MenuItem
            title="Analytics Dashboard"
            to="/analytic"
            icon="/media/icons/duotune/graphs/gra004.svg"
          />
        )} */}
        {permissions?.includes("view-blend-logs") === true && (
          <MenuItem
            title="Blend Summary"
            to="/blendsummary"
            icon="/media/icons/duotune/general/gen031.svg"
          />
        )}
        {permissions?.includes("view-customer-satisfaction-summary") === true && (
          <MenuItem
            title="Customer Satisfaction"
            to="/customerSatisfactionSummary"
            icon="/media/icons/duotune/general/gen029.svg"
          />
        )}
        {permissions?.includes("view-customer-dashboard") === true && (
          <MenuItem
            title="Customer Dashboard"
            to="/customerDashboard"
            icon="/media/icons/duotune/general/gen031.svg"
          />
        )}
        {permissions?.includes("create-customer") === true ||
          permissions?.includes("view-customer") === true ? (
          <MenuInnerWithSub
            title="Customers"
            to="/customers"
            hasArrow={true}
            icon="/media/icons/duotune/communication/com014.svg"
            menuPlacement="right-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {permissions?.includes("create-customer") === true && (
              <MenuItem
                title="Create Customer"
                to="/customers/create"
                hasBullet={true}
              />
            )}
            {permissions?.includes("view-customer") === true && (
              <MenuItem
                title="Customers List"
                to="/customers/list"
                hasBullet={true}
              />
            )}
          </MenuInnerWithSub>
        ) : (
          ""
        )}
         {permissions?.includes("create-customer-account") === true ||
          permissions?.includes("view-customer-account") === true ? (
          <MenuInnerWithSub
            title="Customer Accounts"
            to="/customer/accounts"
            hasArrow={true}
            icon="/media/icons/duotune/communication/com014.svg"
            menuPlacement="right-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {permissions?.includes("create-customer-account") === true && (
              <MenuItem
                title="Create Customer Account"
                to="/customer/accounts/create"
                hasBullet={true}
              />
            )}
            {permissions?.includes("view-customer-account") === true && (
              <MenuItem
                title="Customer Accounts List"
                to="/customer/accounts/list"
                hasBullet={true}
              />
            )}
          </MenuInnerWithSub>
        ) : (
          ""
        )}
        {permissions?.includes("create-location") === true ||
          permissions?.includes("view-location") === true ? (
          <MenuInnerWithSub
            title="Locations"
            to="/locations"
            hasArrow={true}
            icon="/media/icons/duotune/maps/map008.svg"
            menuPlacement="right-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {permissions?.includes("create-location") === true && (
              <MenuItem
                title="Create Location"
                to="/locations/create"
                hasBullet={true}
              />
            )}
            {permissions?.includes("view-location") === true && (
              <MenuItem
                title="Locations List"
                to="/locations/list"
                hasBullet={true}
              />
            )}
            {permissions?.includes("view-location") === true && (
                <MenuItem
                  to="/locations/flavorPrice"
                  title="Location flavor pricing chart"
                  hasBullet={true}
                />
            )}
          </MenuInnerWithSub>
        ) : (
          ""
        )}
        {permissions?.includes("create-machine") === true ||
          permissions?.includes("view-machine") === true ? (
          <MenuInnerWithSub
            title="Machines"
            to="/machines"
            hasArrow={true}
            icon="/media/icons/duotune/electronics/elc002.svg"
            menuPlacement="right-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {permissions?.includes("create-machine") === true && (
              <MenuItem
                title="Create Machine"
                to="/machines/create"
                hasBullet={true}
              />
            )}
            {permissions?.includes("view-machine") === true && (
              <MenuItem
                title="Machines List"
                to="/machines/list"
                hasBullet={true}
              />
            )}
          </MenuInnerWithSub>
        ) : (
          ""
        )}
        {permissions?.includes("create-logJob") === true ||
          permissions?.includes("view-logJob") === true ? (
          <MenuInnerWithSub
            title="Jobs"
            to="/job"
            hasArrow={true}
            icon="/media/icons/duotune/finance/fin006.svg"
            menuPlacement="right-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {permissions?.includes("create-logJob") === true && (
              <MenuItem title="Create Job" to="/job/create" hasBullet={true} />
            )}
            {permissions?.includes("view-logJob") === true && (
              <MenuItem title="Jobs List" to="/job/list" hasBullet={true} />
            )}
          </MenuInnerWithSub>
        ) : (
          ""
        )}
        {permissions?.includes("create-ticket") === true ||
          permissions?.includes("view-ticket") === true ||
          permissions?.includes("schedule-ticket") === true ? (
          <MenuInnerWithSub
            title="Tickets"
            to="/tickets"
            hasArrow={true}
            icon="/media/icons/duotune/finance/fin003.svg"
            menuPlacement="right-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {permissions?.includes("create-ticket") === true && (
              <MenuItem
                title="Create Ticket"
                to="/tickets/create"
                hasBullet={true}
              />
            )}
            {permissions?.includes("view-ticket") === true && (
              <MenuItem
                title="Ticket Logs"
                to="/tickets/list"
                hasBullet={true}
              />
            )}
            {permissions?.includes("schedule-ticket") === true && (
              <MenuItem
                title="Schedule Tickets"
                to="/tickets/calender"
                hasBullet={true}
              />
            )}
          </MenuInnerWithSub>
        ) : (
          ""
        )}
        {/* {permissions?.includes("create-survey") === true ||
          permissions?.includes("view-survey") === true ? (
          <MenuInnerWithSub
            title="Survey"
            to="/masters/survey"
            hasArrow={true}
            icon="/media/icons/duotune/text/txt009.svg"
            menuPlacement="right-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {permissions?.includes("create-survey") === true && (
              <MenuItem
                title="Create Survey"
                to="/masters/survey/create"
                hasBullet={true}
              />
            )}
            {permissions?.includes("view-survey") === true && (
              <MenuItem
                title="Survey List"
                to="/masters/survey/list"
                hasBullet={true}
              />
            )}
          </MenuInnerWithSub>
        ) : (
          ""
        )}
        {permissions?.includes("create-events") === true ||
          permissions?.includes("view-events") === true ? (
          <MenuInnerWithSub
            title="Events"
            to="/masters/events"
            hasArrow={true}
            icon="/media/icons/duotune/general/gen014.svg"
            menuPlacement="right-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {permissions?.includes("create-events") === true && (
              <MenuItem
                title="Create Event"
                to="/masters/events/create"
                hasBullet={true}
              />
            )}
            {permissions?.includes("view-events") === true && (
              <MenuItem
                title="Event List"
                to="/masters/events/list"
                hasBullet={true}
              />
            )}
          </MenuInnerWithSub>
        ) : (
          ""
        )} */}
      {permissions?.includes("view-offline-blend-logs") === true ||
          permissions?.includes("view-blend-logs") === true ? (
            <MenuInnerWithSub
            title="Blend"
            to="/blends"
            hasArrow={true}
            icon="/media/icons/duotune/general/gen031.svg"
            menuPlacement="right-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
            >
            {permissions?.includes("view-blend-logs") === true && (
             <MenuItem
              title="Blend Logs"
              to="/blends/list"
              hasBullet={true}
              />
            )}
            {permissions?.includes("view-offline-blend-logs") === true && (
             <MenuItem
              title="Download Blend Logs"
              to="/blends/download"
              hasBullet={true}
            />
            )}
          </MenuInnerWithSub>
        ) : (
          ""
        )}
           <MenuItem
            title="Work Orders"
            to="/workOrder"
            icon="/media/icons/duotune/general/gen063.svg"
          />
        {permissions?.includes("create-user") === true ||
          permissions?.includes("view-user") === true ||
          permissions?.includes("create-role") === true ||
          permissions?.includes("view-role") === true ? (
          <MenuInnerWithSub
            title="Users"
            to="/users"
            hasArrow={true}
            icon="/media/icons/duotune/communication/com013.svg"
            menuPlacement="right-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {permissions?.includes("create-user") === true && (
              <MenuItem
                title="Create User"
                to="/users/create"
                hasBullet={true}
              />
            )}
            {permissions?.includes("view-user") === true && (
              <MenuItem title="Users List" to="/users/list" hasBullet={true} />
            )}

            {permissions?.includes("create-role") === true ||
              permissions?.includes("view-role") === true ? (
              <MenuInnerWithSub
                title="Roles"
                to="/users/role"
                hasArrow={true}
                hasBullet={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                {" "}
                {permissions?.includes("create-role") === true && (
                  <MenuItem
                    title="Create Role"
                    to="/users/role/create"
                    hasBullet={true}
                  />
                )}
                {permissions?.includes("view-role") === true && (
                  <MenuItem
                    title="Roles List"
                    to="/users/role/list"
                    hasBullet={true}
                  />
                )}
              </MenuInnerWithSub>
            ) : (
              ""
            )}
          </MenuInnerWithSub>
        ) : (
          ""
        )}
        {permissions?.includes("create-issue-reported") === true ||
          permissions?.includes("create-machine-service") === true ||
          permissions?.includes("create-marketing") === true ||
          permissions?.includes("create-operation") === true ||
          permissions?.includes("view-issue-reported") === true ||
          permissions?.includes("view-machine-service") === true ||
          permissions?.includes("view-marketing") === true ||
          permissions?.includes("view-operation") === true ||
          permissions?.includes("create-business-model") === true ||
          permissions?.includes("view-business-model") === true ||
          permissions?.includes("create-board") === true ||
          permissions?.includes("view-board") === true ||
          permissions?.includes("create-blender") === true ||
          permissions?.includes("view-blender") === true ||
          permissions?.includes("create-machine-serial-number") === true ||
          permissions?.includes("view-machine-serial-number") === true ||
          permissions?.includes("create-marketing-material") === true ||
          permissions?.includes("view-marketing-material") === true ||
          permissions?.includes("create-distributor") === true ||
          permissions?.includes("view-distributor") === true ||
          permissions?.includes("create-deployment-type") === true ||
          permissions?.includes("view-deployment-type") === true ||
          permissions?.includes("create-region") === true ||
          permissions?.includes("view-region") === true ||
          permissions?.includes("create-market-segment") === true ||
          permissions?.includes("swap-deployment-manager-location") ||
          permissions?.includes("view-market-segment") === true ||
          permissions?.includes("create-diagnosis") === true ||
          permissions?.includes("view-diagnosis") === true ||
          permissions?.includes("create-cup-holder") === true ||
          permissions?.includes("view-cup-holder") === true ||
          permissions?.includes("create-gas-supplier") === true ||
          permissions?.includes("view-gas-supplier") === true ||
          permissions?.includes("create-tube-shield") === true ||
          permissions?.includes("view-tube-shield") === true ||
          permissions?.includes("create-location-type") === true ||
          permissions?.includes("view-location-type") === true ? (
          <MenuInnerWithSub
            title="Masters"
            to="/masters"
            hasArrow={true}
            isMega={true}
            icon="/media/icons/duotune/ecommerce/ecm008.svg"
            menuPlacement="right-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MegaMenu />
          </MenuInnerWithSub>
        ) : (
          ""
        )}
      </MenuInnerWithSub>
    </>
  );
}
