import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

interface CreateMachineInput {
  name: string;
  deploymentDate: string;
  deployingPersonId?: string;
  serialNumber: string;
  hardwareVersion?: number;
  softwareVersion?: number;
  isActive: boolean;
  remark: string;
  connectionStatus: string;
  blenderId: string;
  boardId: string;
  cupHolderId: string;
  machineId: string;
  machineStatusId: string;
}

export async function createMachine(input: CreateMachineInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation createMachine (
          $input: CreateMachineInput!
        ){
          createMachine(
            createMachineInput: $input
            ) {
                id
                uuid
                serialNumber
                remark
                name
                deploymentDate
                locationId
                machineStatusId
                deployingPersonId
                softwareVersion
                hardwareVersion
                createdById
                updatedById
                isActive
                retiredAt
                isPilot
                trialEndDate
                tubeShieldId
                createdBy {
                  id
                  firstName
                  lastName
                  email
                  roleId
                  slackId
                  resetPasswordKey
                  createdAt
                  updatedAt
                }
                deployingPerson {
                  id
                  firstName
                  lastName
                  createdAt
                  updatedAt
                }
                location {
                  id
                  customerId
                  name
                  address
                  regionId
                  contactName
                  contactPhone
                  marketSegmentId
                  deploymentManagerId
                  locationTypeId
                  targetLaunchDate
                  noOfBlenders
                  noOfFreezers
                  distributorId
                  samplingRecommendedTime
                  samplingRecommendedDays
                  createdById
                  createdAt
                  updatedById
                  updatedAt
                  isActive
                }
              }
        }
        `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));

  return data;
}
export async function getReplaceMachines(
  where: {} = {},
  inStock = true,
  extraFilter: {} = {}
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `query getMachines(
        $where: MachinesResolver_GetMachines_FilterInputType
        $inStock:Boolean!
        $extraFilter: MahineExtraFilterInputs  
    ){
    getMachines(where: $where ,inStock:$inStock,extraFilter: $extraFilter) {
      data {
        id
        uuid
        name
        serialNumber
        location {
          name
        }
      }
  }}`,
  variables: { where, inStock, extraFilter },
},
});
return data?.data?.data?.getMachines;
}
export async function getMachines(
  where: {} = {},
  inStock = false,
  pagination: {} | undefined = undefined,
  extraFilter: {} = {}
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `query getMachines(
              $where: MachinesResolver_GetMachines_FilterInputType
              $inStock:Boolean!
              $paginate:PaginatorArgs
              $extraFilter: MahineExtraFilterInputs 
        ){
        getMachines(where: $where ,inStock:$inStock,paginate: $paginate,extraFilter: $extraFilter) {
          data {
            id
            uuid
            serialNumber
            macAddress
            name
            deploymentDate
            locationId
            deployingPersonId
            softwareVersion
            hardwareVersion
            connectionStatus
            createdById
            createdAt
            updatedById
            updatedAt
            isActive
            machineStatusId
            machineStatus{
              id
              name
            }
            lastSanitizerReplacedAt
            remark
            lastHearbeatSyncAt
            lastSanitizeTime
            lastFilterReplacedAt
            retiredAt
            retiredById
            isRetired
            blenderId
            boardId
            cupHolderId
            tubeShieldId
            noteRelatedToMechanicalHardwareVersion
            isPilot
            isPolyfuses
            trialEndDate
            metas {
              metaKey
              metaValue
              createdAt
            }
            blender{
              id
              title
            }
            board {
              id
              title
            }
            tubeShield{
              id
              name
              description
            }
            retiredBy {
              id
              firstName
              lastName
        
            }
            cupHolder {
              id
              title
            }
            updatedBy {
              id
              firstName
              lastName
            }
            deployingPerson {
              id
              firstName
              lastName
            }
            location {
              id
              customerId
              customer{
                id
                name
                customerCompanyName
                isVip
              }
              name
              address
              regionId
              contactName
              contactPhone
              marketSegmentId
              deploymentManagerId
              deploymentManager{
                firstName
              }
              locationTypeId
              targetLaunchDate
              noOfBlenders
              noOfFreezers
              distributorId
              samplingRecommendedTime
              samplingRecommendedDays
              createdById
              createdAt
              updatedById
              updatedAt
              isActive 
            }
          }
          totalPages
          total
          perPage
          page
        }
      }`,
      variables: { where, inStock, paginate: pagination, extraFilter },
    },
  });
  return data?.data?.data?.getMachines;
}

export async function getMachinesNameAndSerials(
  where: {} = {},
  inStock = false,
  pagination: {} | undefined = undefined
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `query getMachines(
              $where: MachinesResolver_GetMachines_FilterInputType
              $inStock:Boolean!
              $paginate:PaginatorArgs
        ){
        getMachines(where: $where ,inStock:$inStock,paginate: $paginate) {
          data {
            id
            uuid
            serialNumber
            name
            locationId
           }
          totalPages
          total
          perPage
          page
        }
      }`,
      variables: { where, inStock, paginate: pagination },
    },
  });
  return data?.data?.data?.getMachines;
}

interface UpdateMachineInput {
  id?: string;
  name: string;
  deploymentDate: string;
  deployingPersonId: string | undefined;
  serialNumber: string;
  remark: string;
  softwareVersion?: string;
  hardwareVersion?: string | number;
  connectionStatus: string;
  isRetired: boolean;
  blenderId: string;
  boardId: string;
  cupHolderId: string;
  machineStatusId : string;
}
export async function updatemachine(input: UpdateMachineInput) {
  let params = { ...input };
  delete params["id"];
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
      mutation updatemachine (
        $id: String!
        $input: UpdateMachineInput!  
            ){
          updatemachine(
            id: $id
            updateMachineInput: $input
          ) {
            id
            uuid
            serialNumber
            name
            remark
            deploymentDate
            locationId
            deployingPersonId
            lastHearbeatSyncAt
            lastSanitizeTime
            softwareVersion
            hardwareVersion
            createdById
            updatedById
            isActive
            machineStatusId
            machineStatus {
              id
              name
            }
            isPilot
            trialEndDate
            connectionStatus
            createdBy {
              id
              firstName
              lastName
            }
            isRetired
            blenderId
            boardId
            cupHolderId
            tubeShieldId
            retiredAt
            retiredBy {
              id
              firstName
              lastName
            }
            updatedBy {
              id
              firstName
              lastName
            }
            deployingPerson {
              id
              firstName
              lastName
            }
            location {
              id
              customerId
              name
              address
              regionId
              contactName
              contactPhone
              marketSegmentId
              deploymentManagerId
              locationTypeId
              targetLaunchDate
              noOfBlenders
              noOfFreezers
              distributorId
              samplingRecommendedTime
              samplingRecommendedDays
              createdById
              createdAt
              updatedById
              updatedAt
              isActive
            }
            blender {
              id
              versionNo
              title
            }
            board {
              id
              versionNo
              title
            }
            cupHolder {
              id
              title
            }
            tubeShield {
              id
              name
              description
            }
          }
        }
      `,
      variables: {
        input: params,
        id: input.id,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function updateMachineStatus(id: string, isActive: boolean) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
       mutation ($id: String!, $isActive: Boolean! ) {
        updateMachineStatus(
          updateMachineStatusInput: { id: $id, isActive: $isActive }
              )
            }
            `,
      variables: {
        id,
        isActive,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
export async function changeMachineLocation(
  id: string,
  locationId: string,
  machineStatusId: string,
  isPilot: boolean,
  trialEndDate: string,
  deployingPersonId: string
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation changeMachineLocation (
                  $id: String!,
                  $locationId: String!,
                  $machineStatusId: String!,
                  $deployingPersonId: String!,
                  $isPilot: Boolean,
                  $trialEndDate: String
              ){
                changeMachineLocation(
                  changeMachineLocationInput: {
                id: $id
                locationId:$locationId
                isPilot: $isPilot
                trialEndDate: $trialEndDate
                machineStatusId: $machineStatusId
                deployingPersonId: $deployingPersonId
              }
            ) {
              id
              uuid
              serialNumber
              name
              deploymentDate
              locationId
              deployingPersonId
              machineStatusId
              softwareVersion
              hardwareVersion
              connectionStatus
              createdById
              createdAt
              updatedById
              updatedAt
              isActive
              isPilot
              trialEndDate
              remark
              isRetired
              retiredAt
              retiredBy {
                id
                firstName
                lastName
              }
              createdBy {
                id
                firstName
                lastName
              }
              updatedBy {
                id
                firstName
                lastName
              }
              deployingPerson {
                id
                firstName
                lastName
              }
              location {
                id
                uuid
                customerId
                name
                address
                regionId
              }
              machineStatus {
                id
                name
              }
            }
          }
        `,
      variables: {
        id,
        locationId,
        machineStatusId,
        isPilot,
        trialEndDate,
        deployingPersonId,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getSoftwareVersions() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query {
              getSoftwareVersionList 
            }
            `,
      variables: {},
    },
  });
  return data?.data?.data?.getSoftwareVersionList ?? [];
}

export async function getHardwareVersionsList() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query {
                  getHardwareVersionList

            }
            `,
      variables: {},
    },
  });
  return data?.data?.data?.getHardwareVersionList;
}

export async function getMachineStatusList() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query {
          getMachineStatus{
            id
            name
            description
          }
        }
      `,
      variables: {},
    },
  });
  return data?.data?.data?.getMachineStatus;
}

export async function getHistory(
  inputs: {} = {},
  pagination: {},
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `query getHistory(
              $inputs: GetHistoryInputs!
              $paginate:PaginatorArgs
        ){
        getHistory(inputs: $inputs,paginate: $paginate) {
          data {
            id
            targetRowId
            humanReadableHistory
            dataAfterExecutedOperation
            operationExecutedAt
          }
          totalPages
          total
          perPage
          page
        }
      }`,
      variables: { inputs, paginate: pagination },
    },
  });
  return data?.data?.data?.getHistory;
}


export async function getMachineRevisions(
  inputs: {} = {}
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `query getMachineRevisions(
              $inputs: GetMachineRevisionInputs!
        ){
          getMachineRevisions(inputs: $inputs) {
            machineRevision
        }
      }`,
      variables: { inputs },
    },
  });
  return data?.data?.data?.getMachineRevisions?.machineRevision;
}