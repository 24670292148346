import { FC, Suspense, useState } from 'react';
import { getTicketListById } from './ViewTicket';
import { ViewTicket_getTicketQuery } from './__generated__/ViewTicket_getTicketQuery.graphql'
import { useNavigate } from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';
import Modal from 'react-responsive-modal';
import toast, { Toaster } from "react-hot-toast";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import { changeTicketType } from "../../models/_updateTickets";

type Props = {
  changeTicketTypeModal: boolean
  currentTicketId: string
  handleCloseTicketType: () => void
  refreshTable?: any
}
const changeTicketTypeSchema = yup.object({
  ticketType: yup.string().required("Ticket Type is required"),
})
export const ChangeTicketTypeModal: FC<Props> = ({
  changeTicketTypeModal,
  handleCloseTicketType,
  currentTicketId,
  refreshTable
}
) => {
  const navigate = useNavigate()
  if (!currentTicketId) {
    navigate("/tickets/list");
  }
  const TicketRecord = useLazyLoadQuery<ViewTicket_getTicketQuery>(
    getTicketListById,
    {
      id: currentTicketId ?? ""

    },
    {
      fetchPolicy: "network-only",
    },
  )
  const ticket: any = TicketRecord?.getTicket ?? []

  if (ticket == null) {
    navigate("/tickets/list");
  }

  const [loading, setLoading] = useState(false);
  const ticketTypes = [
    {
      id: "REACTIVE_MAINTANANCE",
      name: "Reactive Maintenance",
    },
    {
      id: "PREVENTIVE_MAINTANANCE",
      name: "Preventive Maintenance",
    },
    {
      id: "MARKETING_OTHER",
      name: "Marketing",
    },
    {
      id: "LAB",
      name: "Lab",
    },
  ]

  return (
    <div>
      <Suspense>
        <Modal
          open={changeTicketTypeModal}
          onClose={() => handleCloseTicketType()}
          role="modal"
          center
          classNames={{
            root: "content-wrapper",
          }}
        >
          <Formik
            enableReinitialize
            initialValues={{
              id: ticket.id ?? "",
              ticketType: ticket.ticketType ?? "",
            }}
            validationSchema={changeTicketTypeSchema}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              setLoading(true);

              let ticketType = values.ticketType;

              switch (ticketType) {
                case "REACTIVE_MAINTANANCE":
                  ticketType = "Reactive Maintanance";
                  break;
                case "PREVENTIVE_MAINTANANCE":
                  ticketType = "Preventive Maintanance";
                  break;
                case "MARKETING_OTHER":
                  ticketType = "Marketing/Others";
                  break;
                case "LAB":
                  ticketType = "Lab";
                  break;
              }
              const updateData = {
                id: values.id,
                ticketType,
              };
              const result = await changeTicketType(
                updateData.id,
                updateData.ticketType,
              );
              if (result?.data?.data?.changeTicketType) {
                toast.success("Ticket type updated successfully");
                setLoading(false)
                handleCloseTicketType()
                resetForm()
                if (typeof refreshTable !== 'undefined') {
                  refreshTable()
                }
              } else {
                const messages = result?.data?.errors.map((e: any) => e.message);
                toast.error(messages.join(","));
                setLoading(false)
              }
            }}
          >
            {({
              handleSubmit,
              values,
              setFieldValue,
            }) =>
              <form
                id="kt_modal_add_lesson_form"
                className="form"
                onSubmit={handleSubmit} autoComplete="off"
                noValidate>
                <div className="modal-header p-2 pb-4 mb-4">
                  <h4 className="modal-title fs-1">
                    Change Ticket Type - {ticket.uuid}
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12 mt-2 pt-2">
                      <label className="form-label required fw-bold d-block fs-4">
                        Ticket Type
                      </label>
                      {Array.from(ticketTypes).map((value: any, id) => (
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                          <label
                            className="form-check-label fs-5 mb-0 ms-2"
                            key={id}
                          >
                            <input
                              id="ticketType"
                              className="form-check-input"
                              type="radio"
                              name="ticketType"
                              value={value.id}
                              checked={values.ticketType === value.id}
                              onChange={() =>{
                                setFieldValue("ticketType", value.id)
                              }}
                            />
                            <span className="form-check-label fs-5 mb-0 ms-2">
                              {value.name}
                            </span>
                          </label>
                        </div>
                      ))}
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="ticketType" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer mt-6 p-0 justify-content-start">
                  <button
                    type="submit"
                    className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
                  >
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!loading && (
                      <span className="indicator-label">SUBMIT</span>
                    )}
                  </button>
                </div>
              </form>}
          </Formik>
        </Modal>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </Suspense>

    </div>
  )
}

export default ChangeTicketTypeModal


