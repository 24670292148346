import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import clsx from "clsx";
import Select from "react-select";
import { createCustomerAccount, getCustomerRoleList } from "../../models/_customerAccounts";
import { getLocations } from "../../models/_location";
import { getCustomers } from "../../models/_customer";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  name: yup.string().required("name is required").trim(),
  email: yup
    .string()
    .required("Email is required")
    .email("Enter valid email")
    .trim(),
  roleId: yup.string().required("Customer role is required"),
  customerId: yup.string().required("Customer is required"),
  password: yup.string().required("Password is required").trim(),
});

const CreateUser = () => {
  const navigate = useNavigate();
  const resetRole = React.useRef<any>(null);
  const resetLocation = React.useRef<any>(null);
  const resetCustomers = React.useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [rolesDB, setRolesDB] = useState([]);
  const [locationDB, setLocationDB] = useState([]);
  const [customerDB, setCustomerDB] = useState([]);

  const fetchdata = async () => {
    try {
      const locationIdRecord = await getLocations();
      const locationIdResults: any = locationIdRecord?.data
        ? locationIdRecord?.data
        : [];
      locationIdResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const customerIdRecord = await getCustomers();
      const customerIdResults: any = customerIdRecord?.data
        ? customerIdRecord?.data
        : [];
      customerIdResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const roleRecord = await getCustomerRoleList();
      const roleResults: any = roleRecord ? roleRecord : [];
      roleResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      setRolesDB(roleResults);
      setCustomerDB(customerIdResults);
      setLocationDB(locationIdResults);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchdata();
  }, []);

  return (
    <>
      <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{ maxWidth: "100%" }}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap">
            <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
              Customer Accounts
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="/" className="text-muted text-hover-primary fs-5">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item text-dark fs-5">
                Customer Accounts
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="card mx-xl-4 mt-9">
        <Formik
          initialValues={{
            name: "",
            email: "",
            roleId: "",
            locationId: "",
            customerId: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            setLoading(true);
            const { name, email, roleId, password, locationId, customerId } =
              values;
            let $inputData: any = {
              name,
              email,
              roleId,
              password,
              customerId,
            };

            if (locationId) $inputData["locationId"] = locationId;

            const result = await createCustomerAccount($inputData);
            if (result?.data?.data?.createCustomerAccount) {
              toast.success("Customer account created successfully");
              resetRole.current.setValue("");
              resetCustomers?.current?.setValue("");
              resetLocation?.current?.setValue("");
              setLoading(false);
              resetForm();
              navigate("/customer/accounts/list");
            } else {
              setSubmitting(false);
              setLoading(false);
              const messages = result?.data?.errors.map((e: any) => e.message);
              toast.error(messages.join(","));
            }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 required fw-bold">
                      Name
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid": Boolean(errors.name) && touched.name,
                      })}
                      name="name"
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 required fw-bold">
                      Email
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid": Boolean(errors.email) && touched.email,
                      })}
                      name="email"
                      id="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 required fw-bold">
                      Password
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.password) && touched.password,
                      })}
                      type="password"
                      name="password"
                      id="password"
                      value={values.password}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="password" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="customer form-label fs-4 required fw-bold">
                      Customers
                    </label>{" "}
                    <Select
                      ref={resetCustomers}
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.customerId) && touched.customerId,
                      })}
                      classNamePrefix="my-react-select"
                      name="customerId"
                      placeholder=""
                      options={Array.from(customerDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("customerId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="customerId" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="customer form-label fs-4 fw-bold">
                      Locations
                    </label>{" "}
                    <Select
                      ref={resetLocation}
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.locationId) && touched.locationId,
                      })}
                      classNamePrefix="my-react-select"
                      name="locationId"
                      placeholder=""
                      options={Array.from(locationDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("locationId", e.value);
                      }}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 required fw-bold">
                      Customer Role
                    </label>
                    <Select
                      ref={resetRole}
                      className={clsx("react-select", {
                        "is-invalid": Boolean(errors.roleId) && touched.roleId,
                      })}
                      classNamePrefix="my-react-select"
                      name="roleId"
                      placeholder=""
                      options={Array.from(rolesDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("roleId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="roleId" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-4 col-12 px-xl-5 p-2 mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary me-4 fs-4 w-100"
                    >
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!loading && (
                        <span className="indicator-label">
                          ADD Customer Account
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </Formik>

        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </div>
    </>
  );
};

export default CreateUser;
