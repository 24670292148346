import _ from "lodash";
import Moment from "moment";
import { getUsers } from "../../../../models/_location";
import { useEffect, useState } from "react";
import { WORK_ORDER_STATUS } from "../../../../../_metronic/helpers/enums";

const WorkOrdersOverview = (props: { workOrders: any; locations: any }) => {
  const { workOrders } = props;
  const [processedWorkOrders, setProcessedWorkOrders] = useState<any[]>([]);

  const getDeploymentManagerNameAndInstallerPersonName = async (
    deploymentMangerid: any,
    installerPersonid: any
  ) => {
    const result = await getUsers();
    if (deploymentMangerid) {
      const sortedDeploymentManagers = result.filter(
        (user: any) => user.isRegionalManager
      );
      const deploymentManagerResults: any = sortedDeploymentManagers
        ? sortedDeploymentManagers
        : [];

      const manager = deploymentManagerResults.find((record: any) => {
        return record.id === deploymentMangerid;
      });

      return manager?.firstName;
    }

    if (installerPersonid) {
      const installerPersonResults: any = result ? result : [];

      const installerPerson = installerPersonResults.find((record: any) => {
        return record.id === installerPersonid;
      });
      return installerPerson?.firstName;
    }
  };

  useEffect(() => {
    const fetchNames = async () => {
      const updatedWorkOrders = await Promise.all(
        workOrders.map(async (workOrder: any) => {
          let deploymentManagerName = "";
          let installerPersonName = "";
          if (workOrder.deploymentManagerId) {
            deploymentManagerName =
              await getDeploymentManagerNameAndInstallerPersonName(
                workOrder.deploymentManagerId,
                false
              );
          }

          if (workOrder.installerId) {
            installerPersonName =
              await getDeploymentManagerNameAndInstallerPersonName(
                false,
                workOrder.installerId
              );
          }
          return {
            ...workOrder,
            deploymentManagerName,
            installerPersonName,
          };
        })
      );
      setProcessedWorkOrders(updatedWorkOrders);
    };

    if (workOrders.length > 0) {
      fetchNames();
    }
  }, [workOrders]);

  return (
    <div>
      <div className="row">
        <h3 className="fw-bolder my-2 ms-xl-9 ms-3 mt-9 card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3">Work Orders</span>
          <div className="text-muted fw-semibold fs-7 mt-2">
            Total workorders :{" "}
            {processedWorkOrders.length ? processedWorkOrders.length : 0}
          </div>
        </h3>
        <div className="col-12">
          <div className="card mt-9 mx-xl-4">
            <div className="card-body w-100 p-5">
              <div className="table-responsive p-0 pb-2 fs-6 border-0 p-5">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <thead>
                    <tr className="fs-5 fw-bold">
                      <th className="fs-5 text-muted">First Name</th>
                      <th className="fs-5 text-muted">Last Name</th>
                      <th className="fs-5 text-muted">
                        Deployment Manager Name
                      </th>
                      <th className="fs-5 text-muted">
                        Installing Person Name
                      </th>
                      <th className="fs-5 text-muted">Status</th>
                      <th className="fs-5 text-muted">General Comments</th>
                      <th className="fs-5 text-muted">Submission Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {processedWorkOrders.length > 0 ? (
                      Array.from(processedWorkOrders).map(
                        (workOrder: any, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>{workOrder?.firstName || ""}</td>
                                <td>{workOrder?.lastName || ""}</td>
                                <td>{workOrder.deploymentManagerName}</td>
                                <td>
                                  {workOrder?.installerName
                                    ? workOrder?.installerName
                                    : workOrder.installerPersonName
                                    ? workOrder.installerPersonName
                                    : ""}
                                </td>
                                <td>
                                  {workOrder?.status
                                    ? (workOrder?.status === WORK_ORDER_STATUS.COMPLETED ? "Completed" : "Pending")
                                    : ""}
                                </td>
                                <td>{workOrder?.comment || ""}</td>
                                <td>
                                  {workOrder?.date
                                    ? Moment.utc(workOrder?.date)
                                        .local()
                                        .format("YYYY-MM-DD")
                                    : ""}
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )
                    ) : (
                      <>
                        <tr>
                          <td
                            align="center"
                            className="text-center align-align-items-center justify-content-center"
                            colSpan={11}
                          >
                            No records found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkOrdersOverview;
