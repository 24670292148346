import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Select from "react-select";
import clsx from "clsx";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import { createScheduleEvent } from "../../../models/_scheduleEvent";
import toast, { Toaster } from "react-hot-toast";
import { SCHEDULE_EVENT_TYPE } from "../constants";

const validationSchema = yup.object({
  scheduleStartDateTime: yup
    .date()
    .required("Schedule event start date and time is required"),
  scheduleEndDateTime: yup
    .date()
    .required("Schedule event end date and time is required")
    .when("scheduleStartDateTime", (scheduleStartDateTime, schema) => {
      return schema.min(
        scheduleStartDateTime,
        "Schedule event end date and time must be greater than or equal to start date and time"
      );
    }),
  locationId: yup.string().required("Location is required"),
  regionalManagerId: yup.string().required("Regional manager is required"),
});

type Props = {
  handleCloseModal: () => void;
  regionalManagerDB: any;
  ticketDB: any;
  locationDB:any;
  machineDB:any;
  setIsEventModalOpen: (value: boolean) => void;
};

const EventModal: React.FC<Props> = ({
  handleCloseModal,
  regionalManagerDB,
  ticketDB,
  locationDB,
  machineDB,
  setIsEventModalOpen,
}) => {
  const [activeTab, setActiveTab] = useState("ticket");
  const [loading, setLoading] = useState(false);
  const [locationMachines, setLocationMachines] = useState([])
  const [locationTickets, setLocationTickets] = useState([])
  const [jobTypeDB] = useState([
    {
      id: "SAMPLE_EVENT",
      name: "Sample event",
    },
    {
      id: "SITE_VISIT",
      name: "Site visit",
    },
    {
      id: "INSTALLATION_FOLLOW_UP",
      name: "Installation Follow-up",
    },
    {
      id: "INSTALLATION",
      name: "Installation",
    },
  ]);

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div
        className="modal show d-block"
        tabIndex={-1}
        role="dialog"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Schedule Event</h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleCloseModal}
              ></button>
            </div>
            <Formik
              initialValues={{
                referenceId: "",
                regionalManagerId: "",
                scheduleStartDateTime: new Date(),
                scheduleEndDateTime: new Date(
                  new Date().getTime() + 24 * 60 * 60 * 1000
                ),
                locationId: "",
                machineId: "",
                jobType: "",
                workOrderFirstName: "",
                workOrderLastName: "",
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, { resetForm, setSubmitting }) => {
                setLoading(true);
                const {
                  regionalManagerId,
                  referenceId,
                  jobType,
                  scheduleEndDateTime,
                  scheduleStartDateTime,
                  locationId,
                  machineId,
                  workOrderFirstName,
                  workOrderLastName,
                } = values;
                let $inputData: any = {
                  regionalManagerId,
                  scheduleStartDateTime,
                  scheduleEndDateTime,
                  locationId,
                };

                if (activeTab === "ticket") {
                  if(referenceId) $inputData["referenceId"] = referenceId;
                  $inputData["eventType"] = SCHEDULE_EVENT_TYPE.TICKET;
                }

                if (activeTab === "workorder") {
                  if(workOrderFirstName && workOrderLastName){
                    $inputData["workOrderFirstName"] = workOrderFirstName;
                    $inputData["workOrderLastName"] = workOrderLastName;
                  }
                  $inputData["eventType"] = SCHEDULE_EVENT_TYPE.WORK_ORDER;
                }

                if (activeTab === "job") {
                  if(jobType) $inputData["jobType"] = jobType;
                  $inputData["eventType"] = SCHEDULE_EVENT_TYPE.JOB;
                }

                if (machineId) {
                  $inputData["machineId"] = machineId;
                }

                const result = await createScheduleEvent($inputData);
                if (result?.data?.data?.createScheduleEvent) {
                  toast.success("Schedule event created successfully");
                  setSubmitting(false);
                  setLoading(false);
                  setIsEventModalOpen(false);
                  resetForm();
                } else {
                  setSubmitting(false);
                  setLoading(false);
                  const messages = result?.data?.errors.map(
                    (e: any) => e.message
                  );
                  toast.error(messages.join(","));
                }
              }}
            >
              {({
                handleChange,
                handleSubmit,
                values,
                errors,
                touched,
                setFieldValue,
              }) => (
                <div className="">
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="modal-body">
                      <div className="mb-3">
                        <label
                          htmlFor="locationId"
                          className="form-label required"
                        >
                          Location
                        </label>
                        <Select
                          className={clsx("react-select", {
                            "is-invalid":
                              Boolean(errors.locationId) && touched.locationId,
                          })}
                          classNamePrefix="my-react-select"
                          name="locationId"
                          placeholder=""
                          onChange={(event: any) => {
                            setFieldValue("locationId", event.value);
                            const filteredMachines: any = Array.from(machineDB).filter((machine: any) => machine.locationId == event.value);
                            setLocationMachines(filteredMachines)

                            const filteredTickets: any = Array.from(ticketDB).filter((ticket: any) => ticket.locationId == event.value);
                            setLocationMachines(filteredMachines)
                            setLocationTickets(filteredTickets)
                            setFieldValue("referenceId", "")
                            setFieldValue("machineId", "")
                          }}
                          options={Array.from(locationDB).map((value: any) => {
                            return {
                              value: value.id,
                              label: value.name,
                            };
                          })}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="locationId" />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="machineId" className="form-label">
                          Machine
                        </label>
                        <Select
                          className={clsx("react-select", {
                            "is-invalid":
                              Boolean(errors.machineId) && touched.machineId,
                          })}
                          classNamePrefix="my-react-select"
                          name="machineId"
                          placeholder=""
                          onChange={(event: any) => {
                            setFieldValue("machineId", event.value);
                          }}
                          options={Array.from(locationMachines).map((value: any) => {
                            return {
                              value: value.id,
                              label: value.name,
                            };
                          })}
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="regionalManagerId"
                          className="form-label required"
                        >
                          Regional Manager
                        </label>
                        <Select
                          className={clsx("react-select", {
                            "is-invalid":
                              Boolean(errors.regionalManagerId) &&
                              touched.regionalManagerId,
                          })}
                          classNamePrefix="my-react-select"
                          name="regionalManagerId"
                          placeholder=""
                          onChange={(event: any) => {
                            setFieldValue("regionalManagerId", event.value);
                          }}
                          options={Array.from(regionalManagerDB).map(
                            (value: any) => {
                              return {
                                value: value.id,
                                label: value.firstName,
                              };
                            }
                          )}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="regionalManagerId" />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="form-label required ">
                          Schedule Start Date Time
                        </label>
                        <Datetime
                          closeOnSelect
                          dateFormat="YYYY-MM-DD"
                          timeFormat="HH:mm"
                          value={values.scheduleStartDateTime}
                          onChange={(e: any) => {
                            const dt = e["_d"];
                            setFieldValue("scheduleStartDateTime", dt);
                          }}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="scheduleStartDateTime" />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label required">
                          Schedule End Date Time
                        </label>
                        <Datetime
                          closeOnSelect
                          dateFormat="YYYY-MM-DD"
                          timeFormat="HH:mm"
                          value={values.scheduleEndDateTime}
                          onChange={(e: any) => {
                            const dt = e["_d"];
                            setFieldValue("scheduleEndDateTime", dt);
                          }}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="scheduleEndDateTime" />
                        </div>
                      </div>

                      <ul className="nav mt-3 nav-tabs">
                        <li className="nav-item">
                          <button
                            type="button"
                            className={`nav-link ${
                              activeTab === "ticket"
                                ? "active bg-primary text-white"
                                : ""
                            }`}
                            onClick={() => handleTabClick("ticket")}
                          >
                            Tickets
                          </button>
                        </li>
                        <li className="nav-item">
                          <button
                          type="button"
                            className={`nav-link ${
                              activeTab === "workorder"
                                ? "active bg-primary text-white"
                                : ""
                            }`}
                            onClick={() => handleTabClick("workorder")}
                          >
                            Work Order
                          </button>
                        </li>
                        <li className="nav-item">
                          <button
                          type="button"
                            className={`nav-link ${
                              activeTab === "job"
                                ? "active bg-primary text-white"
                                : ""
                            }`}
                            onClick={() => handleTabClick("job")}
                          >
                            Jobs
                          </button>
                        </li>
                      </ul>

                      <div className="tab-content mt-3">
                        {activeTab === "ticket" && (
                          <div className="tab-pane active">
                            <div className="mb-3">
                              <label className="form-label required">
                                Tickets
                              </label>
                              <Select
                                className="react-select"
                                classNamePrefix="my-react-select"
                                name="referenceId"
                                placeholder="Select Ticket"
                                onChange={(event: any) => {
                                  setFieldValue("referenceId", event.value);
                                }}
                                options={Array.from(locationTickets).map(
                                  (value: any) => {
                                    return {
                                      value: value.id,
                                      label: `${value.title} - ${value.issue} - Open since (${value.daysOpen})`,
                                    };
                                  }
                                )}
                              />
                            </div>
                          </div>
                        )}
                        {activeTab === "workorder" && (
                          <div className="tab-pane active">
                            <div className="row wo-form-group">
                              <h6 className="form-label required">
                                First Name & Last Name
                              </h6>
                              <div className="col-6">
                                <input
                                  id="workOrderFirstName"
                                  type="text"
                                  name="workOrderFirstName"
                                  placeholder="First Name"
                                  className="form-control"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "workOrderFirstName",
                                      e.target.value
                                    );
                                  }}
                                  value={values.workOrderFirstName}
                                />
                              </div>
                              <div className="col-6">
                                <input
                                  id="workOrderLastName"
                                  type="text"
                                  name="workOrderLastName"
                                  placeholder="Last Name"
                                  className="form-control"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "workOrderLastName",
                                      e.target.value
                                    );
                                  }}
                                  value={values.workOrderLastName}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {activeTab === "job" && (
                          <div className="tab-pane active">
                            <div className="mb-3">
                              <label className="form-label">
                                Job Type
                              </label>
                              <Select
                                className="react-select"
                                classNamePrefix="my-react-select"
                                name="jobType"
                                placeholder="Select Job Type"
                                onChange={(event: any) => {
                                  setFieldValue("jobType", event.value);
                                }}
                                options={Array.from(jobTypeDB).map(
                                  (value: any) => {
                                    return {
                                      value: value.id,
                                      label: value.name,
                                    };
                                  }
                                )}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={handleCloseModal}
                      >
                        Close
                      </button>

                      <button type="submit" className="btn btn-primary">
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please Wait..
                            <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                          </span>
                        )}
                        {!loading && (
                          <span className="indicator-label">Save</span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
            <Toaster
              position="bottom-left"
              reverseOrder={false}
              gutter={8}
              containerClassName=""
              containerStyle={{}}
              toastOptions={{
                className: "",
                duration: 3000,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EventModal;