import clsx from "clsx";
import { ErrorMessage } from "formik";
import Datetime from "react-datetime";
import { selectMachineDB } from "../WorkOrderHelper";
import SignaturePad from "react-signature-pad-wrapper";
import Select from "react-select";

const UserInfo = (props: {
  handleChange: any;
  handleBlur: any;
  values: any;
  setFieldValue: any;
  errors: any;
  touched: any;
  handleErrorCounter: any;
  resetDate: any;
  signatureError: any;
  workOrder:any;
  submitClick: any;
  workOrderId:any;
  sigPad: any;
  clearSignature: any;
  storeManagerSigPad: any;
  storeManagerClearSignature:any;
}) => {
  const {
    handleChange,
    handleBlur,
    values,
    setFieldValue,
    workOrder,
    workOrderId,
    errors,
    touched,
    handleErrorCounter,
    resetDate,
    signatureError,
    submitClick,
    sigPad,
    clearSignature,
    storeManagerSigPad,
    storeManagerClearSignature,
  } = props;

  return (
    <>
      <div className="wo-form-group mt-15">
        <h3>General Comments</h3>
        <textarea
          id="comment"
          name="comment"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.comment}
          placeholder="Type here..."
          className="form-control wo-textarea"
          rows={6}
        />
      </div>
      <div className="row mt-15">
        {workOrderId ? (<>
            <div className={"row wo-form-group"}>
             <h6 className="required">First Name & Last Name</h6>
             <div className="col-6">
               <input
                 id="workOrderFirstName"
                 type="text"
                 name="workOrderFirstName"
                 defaultValue={workOrder?.firstName}
                 className={"form-control"}
                 disabled={true}
               />
             </div>
             <div className="col-6 lastName">
               <input
                 id="workOrderLastName"
                 key="workOrderLastName"
                 type="text"
                 defaultValue={workOrder?.lastName}
                 className={"form-control"}
                 disabled={true}
               />
             </div>
           </div>
            </> ):(
             <div
              className={clsx("row wo-form-group", {
                "error-box":
                  Boolean(errors.firstName || errors.lastName) &&
                  touched.firstName,
              })}
              >
             <h6 className="required">First Name & Last Name</h6>
             <div className="col-6">
               <input
                 id="firstName"
                 type="text"
                 key="firstName"
                 name="firstName"
                 placeholder="First Name"
                 onChange={(e) => {
                   setFieldValue("firstName", e.target.value);
                   handleErrorCounter({ ...values, firstName: e.target.value });
                 }}
                 onBlur={handleBlur}
                 value={values.firstName}
                 className={clsx("form-control", {
                   "error-input wo-input form-input":
                     Boolean(errors.firstName) && touched.firstName,
                 })}
               />
             </div>
             <div className="col-6 lastName">
               <input
                 id="lastName"
                 key="lastName"
                 type="text"
                 name="lastName"
                 placeholder="Last Name"
                 onChange={(e) => {
                   setFieldValue("lastName",e.target.value);
                   handleErrorCounter({ ...values, lastName: e.target.value });
                 }}
                 onBlur={handleBlur}
                 value={values.lastName}
                 className={clsx("form-control", {
                   "error-input wo-input form-input":
                     Boolean(errors.lastName) && touched.lastName,
                 })}
               />
             </div>
             <div
               className={clsx("", {
                 "wo-error mt-2": Boolean(errors.firstName) && touched.firstName,
               })}
             >
               {Boolean(errors.firstName) && touched.firstName && (
                 <>
                   <i
                     className="fa fa-exclamation-circle ms-2 error-icon"
                     aria-hidden="true"
                   ></i>{" "}
                   <ErrorMessage name="firstName" />
                 </>
               )}
             </div>
             <div
               className={clsx("", {
                 "wo-error mt-2": Boolean(errors.lastName) && touched.lastName,
               })}
             >
               {Boolean(errors.lastName) && touched.lastName && (
                 <>
                   <i
                     className="fa fa-exclamation-circle ms-2 error-icon"
                     aria-hidden="true"
                   ></i>{" "}
                   <ErrorMessage name="lastName" />
                 </>
               )}{" "}
             </div>
           </div>
          )
          }

        <div className="col-6 date">
          <div
            className={clsx("wo-form-group", {
              "error-box": Boolean(errors.date) && touched.date,
            })}
          >
            <h6 className="required">Date</h6>

            <Datetime
              ref={resetDate}
              className={clsx("form-input", {
                "error-input": Boolean(errors.date) && touched.date,
              })}
              dateFormat="YYYY-MM-DD"
              timeFormat="HH:mm:ss"
              closeOnSelect
              value={values.date}
              onChange={(e: any) => {
                const dt = e["_d"];
                setFieldValue("date", dt);
                values.date = dt;
                handleErrorCounter(values);
              }}
            />
            <div
              className={clsx("", {
                "wo-error mt-4": Boolean(errors.date) && touched.date,
              })}
            >
              <i
                className="fa fa-exclamation-circle ms-5 me-1 error-icon"
                aria-hidden="true"
              ></i>
              <ErrorMessage name="date" />
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-8">
            <div className="col-4 lastName">
            <h6 className="">Store Contact Name</h6>
              <input
                id="storeContactName"
                type="text"
                name="storeContactName"
                placeholder="Store Contact Name"
                onChange={(e) => {
                  setFieldValue("storeContactName",e.target.value);
                  values.storeContactName = e.target.value;
                }}
                onBlur={handleBlur}
                value={values.storeContactName}
                className={clsx("form-control")}
              />
            </div>
            <div className="col-4 lastName">
            <h6 className="">Store Contact Role</h6>
              <input
                id="storeContactRole"
                type="text"
                name="storeContactRole"
                placeholder="Store Contact Role"
                onChange={(e) => {
                  setFieldValue("storeContactRole",e.target.value);
                  values.storeContactRole = e.target.value;
                }}
                onBlur={handleBlur}
                value={values.storeContactRole}
                className={clsx("form-control")}
              />
            </div>
            <div className="col-4 lastName">
            <h6 className="">Store Contact Phone Number</h6>
              <input
                id="storeContactPhoneNumber"
                type="text"
                name="storeContactPhoneNumber"
                placeholder="Store Contact Phone Number"
                onChange={(e) => {
                  setFieldValue("storeContactPhoneNumber",e.target.value);
                  values.storeContactPhoneNumber = e.target.value;  
                }}
                onBlur={handleBlur}
                value={values.storeContactPhoneNumber}
                className={clsx("form-control")}
              />
            </div>
            </div>
            <div
        className={clsx("row mt-4 mb-8")}
      >
       <div 
        className={clsx("col-6", {
          "error-box": Boolean(signatureError) && signatureError && submitClick,
        })}>
          <div className="form-group">
            <h3 className="required">Signature</h3>
            <div
              className={clsx("signature-box mt-4", {
                "error-input":
                  Boolean(signatureError) && signatureError && submitClick,
              })}
            >
              <SignaturePad
                ref={sigPad}
                options={{
                  dotSize: 1,
                  minWidth: 1,
                  maxWidth: 3,
                  penColor: "#81B942",
                  backgroundColor: "#FFFFFF",
                }}
              />
            </div>
          </div>
          <div className="powered-by float-end">
            <button
              type="button"
              className="btn btn-secondary ms-2"
              onClick={clearSignature}
              style={{
                background: "#e6effe",
                padding: "5px 15px",
              }}
            >
              Clear
            </button>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <h3 className="">Store Manager Signature</h3>
            <div
              className={clsx("signature-box mt-4")}
            >
              <SignaturePad
                ref={storeManagerSigPad}
                options={{
                  dotSize: 1,
                  minWidth: 1,
                  maxWidth: 3,
                  penColor: "#81B942",
                  backgroundColor: "#FFFFFF",
                }}
              />
            </div>
          </div>
          <div className="powered-by float-end">
            <button
              type="button"
              className="btn btn-secondary ms-2"
              onClick={storeManagerClearSignature}
              style={{
                background: "#e6effe",
                padding: "5px 15px",
              }}
            >
              Clear
            </button>
          </div>
          </div>
        {submitClick && signatureError ? (
          <div
            className={clsx("", {
              "signature-wo-error mt-4": Boolean(signatureError) && signatureError,
            })}
          >
            <i
              className="fa fa-exclamation-circle ms-2 error-icon"
              aria-hidden="true"
            ></i>
            <span className="ms-2">Signature is Required</span>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default UserInfo;
