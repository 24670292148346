import { Route, Navigate, Routes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { DashboardWrapper } from "../../_smoodi/components/Dashboard/DashboardWrapper";
import CreateCustomer from "../../_smoodi/components/Customers/CreateCustomer";
import CustomersList from "../../_smoodi/components/Customers/CustomersList";
import LocationsList from "../../_smoodi/components/Locations/LocationsList";
import CreateLogJob from "../../_smoodi/components/LogJobs/CreateLogJob";
import LogJobList from "../../_smoodi/components/LogJobs/LogJobList";
import CreateTicket from "../../_smoodi/components/CreateTicket/CreateTicket";
import TicketLogs from "../../_smoodi/components/Tickets/TicketLog";
import StatusChange from "../../_smoodi/components/Tickets/StatusChange";
import CreateUser from "../../_smoodi/components/Users/CreateUser";
import UsersList from "../../_smoodi/components/Users/UsersList";
import CreateRole from "../../_smoodi/components/Users/CreateRole";
import RoleList from "../../_smoodi/components/Users/RolesList";
import CreateMachine from "../../_smoodi/components/Machines/CreateMachine";
import MachinesList from "../../_smoodi/components/Machines/MachinesList";
import CreateLocation from "../../_smoodi/components/Locations/CreateBasicInfoForm";
import { ViewTicket } from "../../_smoodi/components/Tickets/ViewTicket";
import { CustomerHistory } from "../../_smoodi/components/Customers/ViewCustomer/CustomerHistory";
import { ViewJob } from "../../_smoodi/components/LogJobs/ViewJob";
import { LocationHistory } from "../../_smoodi/components/Locations/ViewLocation/LocationHistory";
import { MachineHistory } from "../../_smoodi/components/Machines/ViewMachine/MachineHistory";
import Manual from "../../_smoodi/components/Manual";
import CreateMarketing from "../../_smoodi/components/Masters/Marketing/CreateMarketing";
import MarketingList from "../../_smoodi/components/Masters/Marketing/MarketingList";
import CreateMachineService from "../../_smoodi/components/Masters/MachineService/CreateMachineService";
import MachineServiceList from "../../_smoodi/components/Masters/MachineService/MachineServiceList";
import OperationList from "../../_smoodi/components/Masters/Operation/OperationList";
import CreateOperation from "../../_smoodi/components/Masters/Operation/CreateOperation";
import IssueReportedList from "../../_smoodi/components/Masters/IssueReported/IssueReportedList";
import CreateIssueReported from "../../_smoodi/components/Masters/IssueReported/CreateIssueReported";
import { ProtectedRoute } from "./ProtectedRoute";
import { Unauthorized } from "./Unauthorized";
import CreateMarketingMaterials from "../../_smoodi/components/Masters/MarketingMaterial/CreateMarketingMaterial";
import MarketingMaterialList from "../../_smoodi/components/Masters/MarketingMaterial/MarketingMaterialList";
import { FC, Suspense } from "react";
import { WithChildren } from "../../_metronic/helpers";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import TopBarProgress from "react-topbar-progress-indicator";
import { AnalyticDashboard } from "../../_smoodi/components/Dashboard/AnalyticDashboard";
import ReplaceManager from "../../_smoodi/components/Machines/ReplaceManager";
// import CreateDeployMentType from "../../_smoodi/components/Masters/DeploymentTypes/CreateDeploymentType";
// import DeploymentTypeList from "../../_smoodi/components/Masters/DeploymentTypes/DeploymentTypeList";
import CreateMarketSegment from "../../_smoodi/components/Masters/MarketSegment/CreateMarketSegment";
import MarketSegmentList from "../../_smoodi/components/Masters/MarketSegment/MarketSegmentList";
import CreateLocationRegion from "../../_smoodi/components/Masters/LocationRegion/CreateRegion";
import LocationRegionList from "../../_smoodi/components/Masters/LocationRegion/RegionList";
import CreateDistributor from "../../_smoodi/components/Masters/Distributors/CreateDistributors";
import DistributorList from "../../_smoodi/components/Masters/Distributors/DistributorsList";
import CreateTicketSource from "../../_smoodi/components/Masters/TicketSource/CreateTicketSource";
import TicketSourceList from "../../_smoodi/components/Masters/TicketSource/TicketSourceList";
import BlendLogs from "../../_smoodi/components/BlendLogs/BlendLogs";
import BlendSummary from "../../_smoodi/components/BlendLogs/BlendSummary";
import TicketSummary from '../../_smoodi/components/TicketSummary/TicketSummary'
import CustomerSatisfactionSummary from '../../_smoodi/components/CustomerSatisfaction/CustomerSatisfactionSummary';
import Setting from "../../_smoodi/components/Settings/SettingPage";
import BlendersList from "../../_smoodi/components/Masters/Blenders/BlendersList";
import CreateBlenders from "../../_smoodi/components/Masters/Blenders/CreateBlenders";
import BoardList from "../../_smoodi/components/Masters/Board/BoardList";
import CreateBoard from "../../_smoodi/components/Masters/Board/CreateBoard";
import CreateBusinessModel from "../../_smoodi/components/Masters/BusinessModels/CreateBusinessModel";
import BusinessModelList from "../../_smoodi/components/Masters/BusinessModels/BusinessModelList";
import CreateMachineSerialNumber from "../../_smoodi/components/Masters/MachineSerialNumber/CreateMachineSerialNumber";
import MachineSerialNumberList from "../../_smoodi/components/Masters/MachineSerialNumber/MachineSerialNumberList";
import CreateDiagnosis from "../../_smoodi/components/Masters/Diagnosis/CreateDiagnosis";
import DiagnosisList from "../../_smoodi/components/Masters/Diagnosis/DiagnosisList";
import Calender from "../../_smoodi/components/Tickets/ScheduleTickets/AssignTickets";
import CreateSubTask from "../../_smoodi/components/Tickets/CreateSubTask";
import CreateLocationType from "../../_smoodi/components/Masters/LocationType/CreateLocationType";
import LocationTypeList from "../../_smoodi/components/Masters/LocationType/LocationTypeList";
import FlavorPriceList from "../../_smoodi/components/Locations/FlavorPriceList";
import CupHolderList from "../../_smoodi/components/Masters/CupHolders/CupHolderList";
import CreateCupHolder from "../../_smoodi/components/Masters/CupHolders/CreateCupHolder";
import CreateGasSupplier from "../../_smoodi/components/Masters/GasSupplier/CreateGasSupplier";
import GasSupplierList from "../../_smoodi/components/Masters/GasSupplier/GasSupplierList";
import ChangeLogs from "../../_smoodi/components/ChangeLogs";
import CustomerDashboard from "../../_smoodi/components/CustomerDashboard/CustomerDashboard";
import CreateSurvey from "../../_smoodi/components/Masters/Survey/CreateSurvey";
import SurveyList from "../../_smoodi/components/Masters/Survey/SurveyList";
import CreateEvent from "../../_smoodi/components/Masters/Event/CreateEvent";
import EventList from "../../_smoodi/components/Masters/Event/EventList";
import ViewSummary from "../../_smoodi/components/Masters/Event/ViewSummary/ViewSummary";
import WorkOrderList from "../../_smoodi/components/WorkOrders/WorkOrderList";
import CreateTubeShield from "../../_smoodi/components/Masters/TubeShield/CreateTubeShield";
import TubeShieldList from "../../_smoodi/components/Masters/TubeShield/TubeShieldList";
import MachineStatusList from "../../_smoodi/components/Masters/MachineStatus/MachineStatusList";
import CreateMachineStatus from "../../_smoodi/components/Masters/MachineStatus/CreateMachineStatus";
import { WorkOrderHistory } from "../../_smoodi/components/WorkOrders/View/WorkOrderHistory";
import BlendLogsDownload from "../../_smoodi/components/BlendLogs/BlendLogsDownload";
import CreateCustomerAccounts from "../../_smoodi/components/CustomerAccounts/CreateCustomerAccounts";
import CustomerAccountsList from "../../_smoodi/components/CustomerAccounts/CustomerAccountsList";

const PrivateRoutes = () => {
  const getPermissions: any = localStorage.getItem("permissions");
  const permissions = getPermissions || [];
  return (
    <>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path="auth/*" element={<Navigate to="/dashboard" />} />
          <Route path="dashboard" element={<DashboardWrapper />} />

          {/* Customers */}
          <Route
            path="customers/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-customer")}
              >
                <SuspensedView>
                  <CreateCustomer />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="customers/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-customer")}
              >
                <SuspensedView>
                  <CustomersList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="customers/list/history/:customerId/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-customer")}
              >
                <SuspensedView>
                  <CustomerHistory />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
           {/* Customer Accounts */}
           <Route
            path="customer/accounts/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-customer-account")}
              >
                <SuspensedView>
                  <CreateCustomerAccounts />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="customer/accounts/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-customer-account")}
              >
                <SuspensedView>
                  <CustomerAccountsList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />

          {/* Locations */}
          <Route
            path="locations/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-location")}
              >
                <SuspensedView>
                  <CreateLocation />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="locations/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-location")}
              >
                <SuspensedView>
                  <LocationsList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="locations/flavorPrice/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-location")}
              >
                <SuspensedView>
                  <FlavorPriceList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="locations/list/history/:locationId/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-location")}
              >
                <SuspensedView>
                  <LocationHistory />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          {/* Machines */}
          <Route
            path="machines/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-machine")}
              >
                <SuspensedView>
                  <CreateMachine />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="machines/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-machine")}
              >
                <SuspensedView>
                  <MachinesList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="machines/list/history/:machineId/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-machine")}
              >
                <SuspensedView>
                  <MachineHistory />
                </SuspensedView>
              </ProtectedRoute>
            }
          />

          {/* Jobs */}
          <Route
            path="job/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-logJob")}
              >
                <SuspensedView>
                  <CreateLogJob />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="job/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-logJob")}
              >
                <SuspensedView>
                  <LogJobList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="job/list/viewjob/:jobId/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-logJob")}
              >
                <SuspensedView>
                  <ViewJob />
                </SuspensedView>
              </ProtectedRoute>
            }
          />

          {/* Tickets */}
          <Route
            path="tickets/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-ticket")}
              >
                <SuspensedView>
                  <CreateTicket />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="tickets/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-ticket")}
              >
                <SuspensedView>
                  {" "}
                  <TicketLogs />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="tickets/list/task/create/:ticketId/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-ticket")}
              >
                <SuspensedView>
                  <CreateSubTask />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="tickets/list/statuschange/:ticketId/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("update-ticket")}
              >
                <SuspensedView>
                  <StatusChange />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="tickets/list/viewticket/:ticketId/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-ticket")}
              >
                <SuspensedView>
                  <ViewTicket />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="tickets/calender"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("schedule-ticket")}
              >
                <SuspensedView>
                  <Calender />
                </SuspensedView>
              </ProtectedRoute>
            }
          />

          {/* Users */}
          <Route
            path="users/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-user")}
              >
                <SuspensedView>
                  {" "}
                  <CreateUser />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="users/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-user")}
              >
                <SuspensedView>
                  <UsersList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          {/* Roles */}
          <Route
            path="users/role/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-role")}
              >
                <SuspensedView>
                  <CreateRole />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="users/role/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-role")}
              >
                <SuspensedView>
                  <RoleList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />

          {/* Issue reported */}
          <Route
            path="masters/issues/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-issue-reported")}
              >
                <SuspensedView>
                  <CreateIssueReported />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/issues/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-issue-reported")}
              >
                <SuspensedView>
                  <IssueReportedList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />

          {/* Marketing */}
          <Route
            path="masters/marketing/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-marketing")}
              >
                <SuspensedView>
                  {" "}
                  <CreateMarketing />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/marketing/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-marketing")}
              >
                <SuspensedView>
                  <MarketingList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />

          {/* Machine services */}
          <Route
            path="masters/services/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-machine-service")}
              >
                <SuspensedView>
                  <CreateMachineService />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/services/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-machine-service")}
              >
                <SuspensedView>
                  <MachineServiceList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />

          {/* Operations */}
          <Route
            path="masters/operations/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-operation")}
              >
                <SuspensedView>
                  <CreateOperation />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/operations/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-operation")}
              >
                <SuspensedView>
                  <OperationList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />

          {/* Marketing Materials */}
          <Route
            path="masters/marketing-material/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-marketing-material")}
              >
                <SuspensedView>
                  <CreateMarketingMaterials />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/marketing-material/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-marketing-material")}
              >
                <SuspensedView>
                  <MarketingMaterialList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          {/* Distributors */}
          <Route
            path="masters/distributors/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-distributor")}
              >
                <SuspensedView>
                  <CreateDistributor />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/distributors/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-distributor")}
              >
                <SuspensedView>
                  <DistributorList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />

          {/* Market Segments */}
          <Route
            path="masters/market-segment/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-market-segment")}
              >
                <SuspensedView>
                  <CreateMarketSegment />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/market-segment/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-market-segment")}
              >
                <SuspensedView>
                  <MarketSegmentList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />

          {/* Location Regions */}
          <Route
            path="masters/region/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-region")}
              >
                <SuspensedView>
                  <CreateLocationRegion />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/region/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-region")}
              >
                <SuspensedView>
                  <LocationRegionList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />

          {/* Deployment Types */}
          {/* <Route
            path="masters/deployment-type/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-deployment-type")}
              >
                <SuspensedView>
                  <CreateDeployMentType />
                </SuspensedView>
              </ProtectedRoute>
            }
          />

          <Route
            path="masters/deployment-type/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-deployment-type")}
              >
                <SuspensedView>
                  <DeploymentTypeList />
                </SuspensedView>
              </ProtectedRoute>
            }
          /> */}
          {/* Location Types */}
          <Route
            path="masters/location-type/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-location-type")}
              >
                <SuspensedView>
                  <CreateLocationType />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/location-type/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-location-type")}
              >
                <SuspensedView>
                  <LocationTypeList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />

          {/* Ticket sources */}
          <Route
            path="masters/ticket-source/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-ticket-source")}
              >
                <SuspensedView>
                  <CreateTicketSource />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/ticket-source/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-ticket-source")}
              >
                <SuspensedView>
                  <TicketSourceList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          { /*Blenders */}
          <Route
            path="masters/blenders/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-blender")}
              >
                <SuspensedView>
                  <CreateBlenders />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
           { /*Machine Status */}
          <Route
            path="masters/machine-status/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-machine-status")}
              >
                <SuspensedView>
                  <CreateMachineStatus />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/machine-status/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-machine-status")}
              >
                <SuspensedView>
                  <MachineStatusList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/blenders/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-blender")}
              >
                <SuspensedView>
                  <BlendersList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          { /*Board */}
          <Route
            path="masters/board/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-board")}
              >
                <SuspensedView>
                  <CreateBoard />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/board/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-board")}
              >
                <SuspensedView>
                  <BoardList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          { /* Cup Holders  */}
          <Route
            path="masters/cup-holder/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-cup-holder")}
              >
                <SuspensedView>
                  <CreateCupHolder />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/cup-holder/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-cup-holder")}
              >
                <SuspensedView>
                  <CupHolderList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          {/* Business Model */}
          <Route
            path="masters/business-model/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-business-model")}
              >
                <SuspensedView>
                  <CreateBusinessModel />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/business-model/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-business-model")}
              >
                <SuspensedView>
                  <BusinessModelList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
            {/* Gas Supplier */}
            <Route
            path="masters/gas-supplier/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-gas-supplier")}
              >
                <SuspensedView>
                  <CreateGasSupplier />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/gas-supplier/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-gas-supplier")}
              >
                <SuspensedView>
                  <GasSupplierList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          
          {/*Diagnosis */}
          <Route
            path="masters/diagnosis/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-diagnosis")}
              >
                <SuspensedView>
                  <CreateDiagnosis />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/diagnosis/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-diagnosis")}
              >
                <SuspensedView>
                  <DiagnosisList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          {/*Machine Serial Number */}
          <Route
            path="masters/machine-serial-number/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-machine-serial-number")}
              >
                <SuspensedView>
                  <CreateMachineSerialNumber />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/machine-serial-number/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-machine-serial-number")}
              >
                <SuspensedView>
                  <MachineSerialNumberList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          {/* Survey */}
          <Route
            path="masters/survey/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-survey")}
              >
                <SuspensedView>
                  <CreateSurvey />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/survey/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-survey")}
              >
                <SuspensedView>
                  <SurveyList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
           {/* Event */}
           <Route
            path="masters/events/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-events")}
              >
                <SuspensedView>
                  <CreateEvent />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/events/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-events")}
              >
                <SuspensedView>
                  <EventList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
            <Route
            path="masters/events/list/history/:eventId/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-events")}
              >
                <SuspensedView>
                  <ViewSummary />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
           {/* Tube Shield */}
           <Route
            path="masters/tubeShield/create/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("create-tube-shield")}
              >
                <SuspensedView>
                  <CreateTubeShield />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          <Route
            path="masters/tubeShield/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-tube-shield")}
              >
                <SuspensedView>
                  <TubeShieldList />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          {/* Replace manager  */}
          <Route
            path="masters/replace-manager/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes(
                  "swap-deployment-manager-location"
                )}
              >
                <SuspensedView>
                  <ReplaceManager />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          {/*Blend Logs */}
          <Route
            path="blends/list/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes(
                  "view-blend-logs"
                )}
              >
                <SuspensedView>
                  <BlendLogs />
                </SuspensedView>
              </ProtectedRoute>
            }
          />  

          <Route
            path="blends/download/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes(
                  "view-offline-blend-logs"
                )}
              >
              <SuspensedView>
                <BlendLogsDownload />
              </SuspensedView>
              </ProtectedRoute>
            }
          />  
           {/*Work Order */}
           <Route
            path="workOrder/*"
            element={
              <SuspensedView>
                <WorkOrderList />
              </SuspensedView>
            }
          />
          <Route
            path="workOrder/list/history/:workOrderId/*"
            element={
              <SuspensedView>
                <WorkOrderHistory />
              </SuspensedView>
            }
          />
          <Route
            path="blendSummary/*"
            element={
              <SuspensedView>
                <BlendSummary />
              </SuspensedView>
            }
          />
          <Route
            path="customerSatisfactionSummary"
            element={
              <SuspensedView>
                <CustomerSatisfactionSummary />
              </SuspensedView>
            }
          />
          <Route
            path="customerDashboard"
            element={
              <SuspensedView>
                <CustomerDashboard />
              </SuspensedView>
            }
          />
          {/*Ticket summary */}
          <Route
            path="ticketsummary/*"
            element={
              <SuspensedView>
                <TicketSummary />
              </SuspensedView>
            }
          />
          {/* Quicksight Dashboard  */}
          <Route
            path="analytic/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes("view-quicksight-dashboard")}
              >
                <SuspensedView>
                  <AnalyticDashboard />
                </SuspensedView>
              </ProtectedRoute>
            }
          />
          {/* Setting page */}
          <Route
            path="settingPage/*"
            element={
              <ProtectedRoute
                redirectPath="/unauthorized"
                isAllowed={permissions.includes(
                  "view-settings"
                )}
              >
                <SuspensedView>
                  <Setting />
                </SuspensedView>
              </ProtectedRoute>
            }
          />

          {/* Manual PDF  */}
          <Route path="manual" element={<Manual />} />

          {/* Change Log  */}
          <Route path="changelog" element={<ChangeLogs />} />

          {/* Page Not Found */}
          <Route path="*" element={<Navigate to="/error/404" />} />
        </Route>
        <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>
    </>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
